import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { List } from "immutable";
import { useApprovalAlgorithms } from "hooks/useApprovalAlgorithms";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { removeRedundantSpaces } from "utils/strings";
import { Form } from "components/ui/Form";
import { Input } from "components/ui/Input";
import { DescribedCheckbox } from "components/common/DescribedCheckbox";
import { Select } from "components/ui/Select";
import { DurationsOverrideSelect } from "components/common/DurationsOverrideSelect";
import { Button } from "components/ui/Button";
import { getNameValidators } from "utils/validation/validationUtils";
import { useLoadingState } from "hooks/useLoadingState";
import { useCompany } from "hooks/useCompany";
import { useIntegrations } from "hooks/useIntegrations";
import { VirtualizedRoleSelectInputs } from "components/common/VirtualizedRoleSelectInputs";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { WorkflowOption } from "components/ui/selectOptions/WorkflowOption";
import { useStyles } from "./styles";
import type { TIntegrationResourceRole } from "api/integrationResourceRoles";
import type { TTicketDuration } from "utils/durationsOptions";

interface IProps {
	resource: IntegrationResourceModel;
	resetFormRef?: React.MutableRefObject<(() => void) | undefined>;
	onSubmit: (role: TIntegrationResourceRole) => Promise<void>;
}

export const CreateRoleForm: FC<IProps> = ({ resource, onSubmit, resetFormRef }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const approvalAlgorithms = useApprovalAlgorithms();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const clearVirtualRoleFormRef = useRef<(() => void) | undefined>();
	const inheritApprovalAlgorithm = useMemo(
		() => new ApprovalAlgorithmModel({ id: "[RESOURCE_DEFAULT]", name: t("pages.integration.resource.role.inherit") }),
		[t]
	);
	const company = useCompany();
	const integrations = useIntegrations();
	const integration = integrations?.get(resource.integrationId || "");

	const { isLoading, withLoader } = useLoadingState();
	const [name, setName] = useState("");
	const [approvalAlgorithmId, setApprovalAlgorithmId] = useState<string>(inheritApprovalAlgorithm.id);
	const [allowsRequests, setAllowsRequests] = useState<boolean>(true);
	const [allowedDurations, setAllowedDurations] = useState(List<TTicketDuration>());
	const [overrideAllowedDurations, setOverrideAllowedDurations] = useState(false);
	const [approvalAlgorithmError, setApprovalAlgorithmError] = useState("");
	const [virtualizedRoleId, setVirtualizedRoleId] = useState<string | null>(null);

	const onAlgorithmChange = useCallback(
		(value: ApprovalAlgorithmModel | null) => {
			if (value) {
				const newApprovalAlgorithm = approvalAlgorithms?.get(value?.id || "") || null;
				setApprovalAlgorithmId(newApprovalAlgorithm?.id || inheritApprovalAlgorithm.id);
				setApprovalAlgorithmError("");
			}
		},
		[approvalAlgorithms, inheritApprovalAlgorithm.id]
	);

	const clearForm = useCallback(() => {
		setName("");
		setVirtualizedRoleId(null);
		clearVirtualRoleFormRef.current?.();
		setApprovalAlgorithmId(inheritApprovalAlgorithm.id);
		setOverrideAllowedDurations(false);
		setAllowedDurations(List<TTicketDuration>());
		setAllowsRequests(true);
	}, [inheritApprovalAlgorithm.id]);

	const validate = useCallback(() => {
		let valid = true;

		if (approvalAlgorithmId === null) {
			valid = false;
			setApprovalAlgorithmError(
				t("validationErrors.global.cannotBeEmpty", { field: t("modelsFields.integration.defaultApprovalAlgorithm") })
			);
		}
		return valid;
	}, [approvalAlgorithmId, t]);

	const toggleAllowRequests = useCallback(() => setAllowsRequests(current => !current), []);

	const submit = useCallback(async () => {
		const displayName = removeRedundantSpaces(name);
		if (validate()) {
			try {
				await withLoader(
					onSubmit({
						name: displayName,
						allowsRequests,
						integrationResourceId: resource.id,
						approvalAlgorithmId: approvalAlgorithmId === inheritApprovalAlgorithm.id ? null : approvalAlgorithmId,
						allowedDurations: overrideAllowedDurations ? allowedDurations : null,
						virtualizedRoleId
					} as TIntegrationResourceRole)
				);
				clearForm();
			} catch (e) {
				openGlobalErrorModal(e as Error);
			}
		}
	}, [
		name,
		validate,
		withLoader,
		onSubmit,
		allowsRequests,
		resource.id,
		approvalAlgorithmId,
		inheritApprovalAlgorithm.id,
		overrideAllowedDurations,
		allowedDurations,
		virtualizedRoleId,
		clearForm,
		openGlobalErrorModal
	]);

	const approvalAlgorithmOptions = useMemo(
		() => [inheritApprovalAlgorithm].concat(approvalAlgorithms?.toList().toArray() || []),
		[approvalAlgorithms, inheritApprovalAlgorithm]
	);

	const approvalAlgorithm = useMemo(() => {
		if (approvalAlgorithmId === inheritApprovalAlgorithm.id) {
			return inheritApprovalAlgorithm;
		}
		return approvalAlgorithms?.get(approvalAlgorithmId);
	}, [approvalAlgorithmId, approvalAlgorithms, inheritApprovalAlgorithm]);

	const validators = useMemo(() => getNameValidators(`${t("shared.resourceTypes.role")} ${t("shared.name")}`), [t]);

	const isValid = useMemo(() => {
		const roleNameValid = !validators.some(validate => validate(name));
		const virtualRoleValid = !integration?.virtual || Boolean(virtualizedRoleId);

		return roleNameValid && virtualRoleValid;
	}, [integration?.virtual, name, validators, virtualizedRoleId]);

	useEffect(() => {
		const newAllowedDurations =
			resource?.allowedDurations || integration?.allowedDurations || company?.allowedDurations;
		if (newAllowedDurations) {
			setAllowedDurations(newAllowedDurations);
		}
	}, [company, integration, resource]);

	useEffect(() => {
		if (resetFormRef) {
			resetFormRef.current = clearForm;
		}
	}, [resetFormRef, clearForm]);

	return (
		<Form className={classes.roleForm}>
			<Form.Field>
				<Input
					label={t("pages.integration.resource.role.name")}
					value={name}
					validators={validators}
					onValueChange={setName}
					isRequired
					disabled={!(integration?.manual || integration?.virtual)}
				/>
			</Form.Field>
			<Form.Field>
				<Select
					disabled={!approvalAlgorithms?.size}
					errors={[approvalAlgorithmError]}
					getOptionLabel={getNameLabel}
					isOptionEqualToValue={equality}
					label={t("pages.integration.defaultApprovalAlgorithm")}
					onChange={onAlgorithmChange}
					options={approvalAlgorithmOptions}
					renderOption={WorkflowOption}
					required
					value={approvalAlgorithm || null}
				/>
			</Form.Field>
			<Form.Field className={classes.checkboxField}>
				<DescribedCheckbox
					label={t("pages.integration.resource.role.requestable")}
					description={t("pages.integration.resource.role.requestableDescription")}
					selected={resource.allowsRequests && allowsRequests}
					onClick={toggleAllowRequests}
					disabled={!resource.allowsRequests}
				/>
			</Form.Field>
			{integration?.virtual && (
				<Form.Field className={classes.virtualizedRoleField}>
					<VirtualizedRoleSelectInputs
						setVirtualizedRoleId={setVirtualizedRoleId}
						clearVirtualRoleFormRef={clearVirtualRoleFormRef}
					/>
				</Form.Field>
			)}
			<Form.Field>
				<DurationsOverrideSelect
					overrideAllowedDurations={overrideAllowedDurations}
					setOverrideAllowedDurations={setOverrideAllowedDurations}
					allowedDurations={allowedDurations}
					setAllowedDurations={setAllowedDurations}
					disabled={Boolean(!resource)}
					type="role"
				/>
			</Form.Field>
			<div className={classes.actions}>
				<Button variant="primary" size="medium" type="submit" loading={isLoading} disabled={!isValid} onClick={submit}>
					{t("buttons.create")}
				</Button>
			</div>
		</Form>
	);
};

const getNameLabel = (option: { name: string }) => option.name;
const equality = (option: { id: string }, value: { id: string }) => option.id === value.id;
