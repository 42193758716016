import React, { useMemo } from "react";
import { ApplicationModel } from "models/ApplicationModel";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const ApplicationOption: TOptionComponent<ApplicationModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const Icon = useMemo(() => {
		return option.imageUrl ? getDynamicSizeIcon(<img src={option.imageUrl} />) : undefined;
	}, [option.imageUrl]);

	return (
		<SelectOption
			PrefixIcon={Icon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant="body_reg" content={option.name} />
		</SelectOption>
	);
};

ApplicationOption.isSelectOption = true;

export { ApplicationOption };
