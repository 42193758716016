import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
	container: {
		display: "grid",
		maxHeight: "500px",
		gap: "16px",
		padding: "16px",
		overflowY: "auto"
	},
	singleChip: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	}
});
