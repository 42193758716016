import React from "react";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const BundleOption: TOptionComponent<BundleModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	return (
		<SelectOption
			PrefixIcon={BundleIcon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant="body_reg" content={option.name} />
		</SelectOption>
	);
};

BundleOption.isSelectOption = true;

export { BundleOption };
