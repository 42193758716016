import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { IconPrefix } from "components/ui/IconPrefix";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { CloseIcon } from "components/ui/Icons/CloseIcon";
import { REQUESTABLE_OPTIONS, TRequestableOption } from "components/pages/BulkActionsPage/utils";
import { TextOption } from "components/ui/selectOptions/TextOption";
import type { TOptionComponentProps } from "utils/ui/select";

type TProps = {
	label?: React.ReactNode;
	value: boolean | null;
	onChange: (value: boolean | null) => void;
};

type TOption = TRequestableOption | null;

export const RequestableSelect: FC<TProps> = React.memo(function RequestableSelect({
	className,
	label,
	innerRef,
	value,
	onChange
}) {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.bulkActions.inputs.toThis" });

	const getLabel = useCallback((option: TOption) => (option ? t(option) : ""), [t]);

	const renderLabel = useCallback(
		(option: TOption) => {
			const Icon = option === "yes" ? GrantedIcon : option === "no" ? CloseIcon : null;
			if (!Icon) {
				return null;
			}
			return <IconPrefix size="small" Icon={Icon} content={getLabel(option)} />;
		},
		[getLabel]
	);

	const handleChange = useCallback(
		(option: TOption) => {
			onChange(option === "yes" ? true : option === "no" ? false : null);
		},
		[onChange]
	);

	const selectValue = value === true ? "yes" : value === false ? "no" : null;

	const getOptionRenderer = useCallback((props: TOptionComponentProps<TRequestableOption>) => {
		const Icon = props.option === "yes" ? GrantedIcon : CloseIcon;
		return <TextOption {...props} PrefixIcon={Icon} />;
	}, []);

	return (
		<Select
			className={className}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			label={label ?? t("label")}
			onChange={handleChange}
			renderOption={getOptionRenderer}
			options={REQUESTABLE_OPTIONS}
			placeholder={t("placeholder", { context: "requestable" })}
			renderLabel={renderLabel}
			sort={null}
			value={selectValue}
		/>
	);
});
