import React from "react";
import { UserModel } from "models/UserModel";
import { Avatar } from "components/common/Avatar";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import classNames from "classnames";
import { INHERIT, type TInherit, type TOptionComponentProps } from "utils/ui/select";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";

function UserAvatarOption<T extends UserModel | TInherit>({
	disabled = false,
	getTextContent,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const classes = useStyles();

	const content = option === INHERIT ? (getTextContent ? getTextContent(option) : option) : option.fullName;

	return (
		<SelectOption onSelect={onSelect} isSelected={isSelected} disabled={disabled} value={option} key={optionKey}>
			{option === INHERIT ? (
				<TooltipOnOverflow content={content} textVariant="body_sb" />
			) : (
				<div className={classes.container}>
					<Avatar user={option} size="small" />
					<div className={classes.title}>
						<TooltipOnOverflow
							textVariant="body_med"
							content={content}
							className={classNames(classes.content, { deleted: option.isDeleted })}
						/>
						<TooltipOnOverflow textVariant="text_sm_reg" content={option.email} />
					</div>
				</div>
			)}
		</SelectOption>
	);
}

UserAvatarOption.isSelectOption = true;

export { UserAvatarOption };
