import React, { useMemo } from "react";
import upperFirst from "lodash/upperFirst";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useIsOpenState } from "hooks/useIsOpenState";
import { DropdownButton } from "../DropdownButton";
import { Typography } from "../Typography";
import { useStyles } from "./styles";
import type { TTimezone } from "utils/auditLogs/systemAuditLogTimezone";

export interface IToggleOption {
	value: TTimezone;
	label: string;
	dropdownLabel: string;
}

type TTimezoneDropdownProps = {
	options: IToggleOption[];
	selectedOption: string;
	onSelect: (value: TTimezone) => void;
};
type TTimezoneDropdownButtonProps = {
	options: IToggleOption[];
	selectedOption: string;
	onSelect: (value: TTimezone) => void;
};

const TimezoneDropdown: FC<TTimezoneDropdownProps> = ({ options, selectedOption, onSelect }) => {
	const classes = useStyles();
	const renderedOptions = useMemo(() => {
		return options.map(option => {
			const onClick = () => onSelect(option.value);
			const isSelected = option.value === selectedOption;
			return (
				<div
					key={option.value}
					className={classNames(classes.option, { [classes.selected]: isSelected })}
					onClick={onClick}>
					<Typography variant={isSelected ? "text_title_sb" : "text_reg"}>{option.dropdownLabel}</Typography>
				</div>
			);
		});
	}, [classes.option, classes.selected, onSelect, options, selectedOption]);

	return <div className={classes.dropdown}>{renderedOptions}</div>;
};

export const TimezoneButtonDropdown: FC<TTimezoneDropdownButtonProps> = ({
	selectedOption,
	onSelect,
	options,
	className
}) => {
	const { close, open, isOpen } = useIsOpenState();
	const { t } = useTranslation();

	const getOptionLabel = (value: string) => {
		return options.find(option => option.value === value)?.label || "";
	};

	return (
		<DropdownButton
			size="medium"
			variant="secondary"
			dropdown={<TimezoneDropdown options={options} selectedOption={selectedOption} onSelect={onSelect} />}
			onClose={close}
			onClick={open}
			className={className}
			open={isOpen}
			position="bottom-end">
			<Typography variant={"text_title_sb"}>
				{`${t("pages.auditLog.timezoneToggle.title")}
                 ${upperFirst(getOptionLabel(selectedOption))}`}
			</Typography>
		</DropdownButton>
	);
};
