import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationModel } from "models/IntegrationModel";
import { Select } from "components/ui/Select";
import { sortByName } from "utils/sortUtils";
import { OptionWithIcon } from "components/common/OptionWithIcon";
import { useGraphIntegrations } from "components/pages/IdentityGraphPage/graphHooks";
import { useApplications } from "hooks/useApplications";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { useStyles } from "./styles";

type TBaseProps = {
	disabled?: boolean;
};

type TIdProps = TBaseProps & {
	onChange: (integrationId: string | null) => void;
	value: string | null;
	byId: true;
};

type TValueProps = TBaseProps & {
	onChange: (integration: IntegrationModel | null) => void;
	value: IntegrationModel | null;
	byId?: false;
};
type TProps = TIdProps | TValueProps;

const isEqual = (option: IntegrationModel, value: IntegrationModel) => option.id === value.id;
const getLabel = (option: IntegrationModel) => option.name;
const renderLabel = (option: IntegrationModel) => (
	<OptionWithIcon imageUrl={option.imageUrl}>{option.name}</OptionWithIcon>
);

export const IntegrationsSelect: FC<TProps> = ({
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	value: propValue,
	byId
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { integrations, isLoading } = useGraphIntegrations();
	const applications = useApplications();
	const options = useMemo(
		() =>
			integrations
				? integrations
						.valueSeq()
						.toArray()
						.map(integration => {
							return integration.set(
								"imageUrl",
								(integration.imageUrl ?? applications?.get(integration.applicationId || "")?.imageUrl) || null
							);
						})
				: [],
		[applications, integrations]
	);

	const onChange = useCallback(
		(value: IntegrationModel | null) => {
			if (byId) {
				propOnChange(value?.id ?? null);
			} else {
				propOnChange(value);
			}
		},
		[byId, propOnChange]
	);

	const value = useMemo(() => {
		if (byId) {
			return integrations?.get(propValue || "") ?? null;
		} else {
			return propValue;
		}
	}, [byId, integrations, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.integrationSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			renderOption={IntegrationOption}
			options={options}
			placeholder={t("common.integrationSelectInput.placeholder")}
			renderLabel={renderLabel}
			sort={sortByName}
			value={value}
		/>
	);
};
