import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useIntegrations } from "hooks/useIntegrations";
import { Button } from "components/ui/Button";
import { PageTemplate } from "components/templates/PageTemplate";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { RuleIcon } from "components/ui/Icons/RuleIcon";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { IntegrationCard } from "./components/IntegrationCard";
import { useStyles } from "./styles";

export const IntegrationsPage: FC = ({ className }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const classes = useStyles();
	const integrations = useIntegrations();

	const handleAddIntegrationClick = useCallback(() => {
		navigate("/integrations/create");
	}, [navigate]);

	const handleManageRulesClick = useCallback(() => {
		navigate("/rules");
	}, [navigate]);

	const handleBulkActionsClick = useCallback(() => {
		navigate("/bulkActions");
	}, [navigate]);

	const sortedIntegrations = useMemo(
		() => integrations?.sortBy(integration => integration.name.toUpperCase()),
		[integrations]
	);

	const integrationCards = sortedIntegrations
		?.toList()
		.map(integration => <IntegrationCard key={integration.id} integration={integration} />)
		.toArray();

	return (
		<PageTemplate className={classNames("integrationsPage", className)}>
			<PageTemplate.Title className={classes.titleContainer}>
				{t("pages.integrations.title")}
				<div className={classes.titleActions}>
					<Button prefix={<RuleIcon size="small" />} size="medium" variant="secondary" onClick={handleManageRulesClick}>
						{t("pages.integrations.manageRules")}
					</Button>
					<Button prefix={<EditIcon size="small" />} size="medium" variant="secondary" onClick={handleBulkActionsClick}>
						{t("pages.integrations.bulkActions")}
					</Button>
					<Button size="medium" onClick={handleAddIntegrationClick}>
						{t("pages.integrations.addIntegration")}
					</Button>
				</div>
			</PageTemplate.Title>
			<PageTemplate.Content>
				{integrations === null ? (
					<LoadingSpinner className={classes.spinner} />
				) : (
					<div className={classes.integrations}>{integrationCards}</div>
				)}
			</PageTemplate.Content>
		</PageTemplate>
	);
};
