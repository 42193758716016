import { Map } from "immutable";
import { fromURLSearchParams } from "utils/api/filters";
import type { TBulkActionTabOption } from "./types";
import type { IFilter } from "types/filters";
import type { TFilterName } from "filters";
import type { DirectoryGroupModel } from "models/DirectoryGroupModel";
import type { UserModel } from "models/UserModel";

export const INTEGRATION = "integration";
export const RESOURCE = "resource";
export const ROLE = "role";

export const REQUESTABLE = "requestable" as const;
export const WORKFLOW = "workflow" as const;
export const MAINTAINER = "maintainer" as const;
export const OWNER = "owner" as const;
export const AVAILABLE_ROLE_CHANGES = [WORKFLOW, REQUESTABLE];
export const AVAILABLE_CHANGES = [WORKFLOW, OWNER, MAINTAINER, REQUESTABLE];

export type TChange = (typeof AVAILABLE_CHANGES)[number];

export const ADD = "add" as const;
export const REMOVE = "remove" as const;
export const REPLACE = "replace" as const;
export const AVAILABLE_ACTIONS = [ADD, REMOVE, REPLACE];

export type TAction = (typeof AVAILABLE_ACTIONS)[number];

export type TMaintainersAction = (typeof AVAILABLE_ACTIONS)[number];

const YES = "yes" as const;
const NO = "no" as const;
export const REQUESTABLE_OPTIONS = [YES, NO];
export type TRequestableOption = (typeof REQUESTABLE_OPTIONS)[number];

export type TMaintainerOption = UserModel | DirectoryGroupModel;

export const getIntegrationChangeKey = (change: TChange) => {
	switch (change) {
		case MAINTAINER:
			return "maintainers";
		case OWNER:
			return "ownerUserId";
		case REQUESTABLE:
			return "allowsRequests";
		case WORKFLOW:
			return "defaultApprovalAlgorithmId";
	}
};

export const getResourceChangeKey = (change: TChange) => {
	switch (change) {
		case MAINTAINER:
			return "maintainers";
		case OWNER:
			return "ownerUserId";
		case REQUESTABLE:
			return "allowsRequests";
		case WORKFLOW:
			return "approvalAlgorithmId";
	}
};

export const getRoleChangeKey = (change: TChange) => {
	switch (change) {
		case REQUESTABLE:
			return "allowsRequests";
		case WORKFLOW:
			return "approvalAlgorithmId";
		default:
			return "";
	}
};

export const getChangeKeyToChangeField = (field: string): TChange | null => {
	switch (field) {
		case "maintainers":
			return MAINTAINER;
		case "ownerUserId":
			return OWNER;
		case "allowsRequests":
			return REQUESTABLE;
		case "defaultApprovalAlgorithmId":
		case "approvalAlgorithmId":
			return WORKFLOW;
		default:
			return null;
	}
};

const isBulkActionTabOption = (value: string): value is TBulkActionTabOption => {
	return [INTEGRATION, RESOURCE, ROLE].includes(value as TBulkActionTabOption);
};

export const getStateFromSearchParams = (): { tab: TBulkActionTabOption; filters: IFilter[] } => {
	const searchParams = new URLSearchParams(window.location.search);
	const tab = searchParams.get("tab");
	if (!tab || !isBulkActionTabOption(tab)) return { tab: INTEGRATION, filters: [] };
	const filters = fromURLSearchParams(searchParams, tab);
	return { tab, filters };
};

export const MODEL_TO_ALLOWED_FILTERS = Map<TBulkActionTabOption, TFilterName[]>({
	[INTEGRATION]: [
		"integrationId",
		"integrationName",
		"integrationWorkflow",
		"integrationOwner",
		"integrationMaintainer",
		"integrationAllowRequests"
	],
	[RESOURCE]: [
		"integrationResourceIntegrationId",
		"integrationResourceIntegrationName",
		"integrationResourceType",
		"integrationResourceId",
		"integrationResourceName",
		"integrationResourceTags",
		"integrationResourceWorkflow",
		"integrationResourceOwner",
		"integrationResourceMaintainer",
		"integrationResourceAllowRequests"
	],
	[ROLE]: [
		"integrationResourceRoleIntegrationId",
		"integrationResourceRoleIntegrationName",
		"integrationResourceRoleIntegrationResourceType",
		"integrationResourceRoleIntegrationResourceId",
		"integrationResourceRoleIntegrationResourceName",
		"integrationResourceRoleIntegrationResourceTags",
		"integrationResourceRoleId",
		"integrationResourceRoleName",
		"integrationResourceRoleWorkflow",
		"integrationResourceRoleAllowRequests"
	]
});
