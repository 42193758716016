import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import classNames from "classnames";
import { useTicketUserPermissions } from "hooks/useTicketUserPermissions";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useTicketUpdatesContext } from "context/ticketUpdatesContext";
import { respondToTicket, cancelTicket, getTicket } from "api/tickets";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import { useStyles } from "./styles";
import { RequestedBy } from "./components/RequestedBy";
import { RequestPermissions } from "./components/RequestPermissions";
import { RequestedDuration } from "./components/RequestedDuration";
import { RequestJustification } from "./components/RequestJustification";
import { RequestSummary } from "./components/RequestSummary";
import { RequestApprovalActions } from "./components/RequestApprovalActions";
import { RequestApprovers } from "./components/RequestApprovers";
import { RequestUsersPendingActions } from "./components/RequestUsersPendingActions";
import { RequestPastActions } from "./components/RequestPastActions";
import { getPermissionsGrouping } from "./utils";
import { RequestRetryActions } from "./components/RequestRetryActions";
import type { TicketModel } from "models/TicketModel";
import type { TRequestBarProps } from "./types";
import type { Require } from "types/utilTypes";

const RequestBar: FC<TRequestBarProps> = props => {
	const { request, className, innerRef, type, onRetry } = props;
	const [urlSearchParams] = useSearchParams();
	const [selected, setSelected] = useState(false);
	const { user } = useAuthenticatedUser();
	const { notifyTicketUpdate } = useTicketUpdatesContext();
	const openGlobalErrorModal = useOpenGlobalErrorModal();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const navigate = useNavigate();
	const classes = useStyles();

	const { hasApproverActions, hasReceiverActions, hasCancel, isReceiver, hasRetryActions } = useTicketUserPermissions(
		request,
		user
	);

	const withApprovers = useMemo(
		() =>
			request.status === "waitingForApproval" &&
			request.approvalRequests?.some(
				approvalRequest =>
					approvalRequest.approvalEntities?.some(entity => entity.approverIds.filter(id => id !== user?.id).size > 0) ||
					false
			),
		[request.approvalRequests, request.status, user?.id]
	);

	const onApprovalResponse = useCallback(
		async (response: "approve" | "deny") => {
			const updatedRequest = await respondToTicket(request.id, response === "approve");
			notifyTicketUpdate(updatedRequest);
		},
		[notifyTicketUpdate, request.id]
	);

	const onCancel = useCallback(
		async (requestId: string) => {
			try {
				await cancelTicket(requestId);
				const updatedRequestData = await getTicket(requestId);
				notifyTicketUpdate(updatedRequestData);
				if (props.onCancel) {
					props.onCancel(requestId);
				}
			} catch (error) {
				openGlobalErrorModal(new Error(`Could not cancel ticket: ${error}`));
			}
		},
		[notifyTicketUpdate, openGlobalErrorModal, props]
	);

	const onRetryAction = useCallback(
		async (wantedRequest: TicketModel) => {
			try {
				if (wantedRequest.status === "failed" || wantedRequest.status === "waitingForIT") {
					if (onRetry) {
						const updatedRequest = await onRetry(wantedRequest.id);
						notifyTicketUpdate(updatedRequest);
					}
				}
			} catch (error) {
				openGlobalErrorModal(new Error(`Could not retry ticket: ${error}`));
			}
		},
		[notifyTicketUpdate, onRetry, openGlobalErrorModal]
	);

	const actions = useMemo(() => {
		if (hasApproverActions && (type === "awaitingResponse" || (!type && !isReceiver))) {
			return <RequestApprovalActions respond={onApprovalResponse} />;
		}
		if ((type === "userPending" && hasCancel) || (!type && hasCancel)) {
			return <RequestUsersPendingActions requestId={request.id} cancel={onCancel} />;
		}
		if ((type === "past" && hasReceiverActions) || (!type && hasReceiverActions)) {
			return <RequestPastActions request={request} />;
		}
		if (!type && hasRetryActions) {
			return <RequestRetryActions retry={onRetryAction} request={request} />;
		}
		return undefined;
	}, [
		hasApproverActions,
		type,
		isReceiver,
		hasCancel,
		hasReceiverActions,
		hasRetryActions,
		onApprovalResponse,
		request,
		onCancel,
		onRetryAction
	]);

	const onRequestClick = useCallback(async () => {
		if (props.onClick) await props.onClick(request);
		if (props.openSidebarOnClick) {
			setSelected(true);
			navigate(`?ticketId=${request.id}`);
		}
	}, [navigate, props, request]);

	useEffect(() => {
		if (urlSearchParams.get("ticketId") !== request.id) {
			setSelected(false);
		}
	}, [request.id, urlSearchParams]);

	const permissionsGrouping = useMemo(() => getPermissionsGrouping(request), [request]);
	return (
		<div
			onClick={onRequestClick}
			className={classNames(
				classes.container,
				{
					[classes.clickable]: !!(props.onClick || props.openSidebarOnClick),
					[classes.selected]: selected || urlSearchParams.get("ticketId") === request.id
				},
				className
			)}
			ref={element => {
				if (innerRef) {
					if (typeof innerRef === "function") {
						innerRef(element);
					} else {
						(innerRef as MutableRefObject<HTMLDivElement | null>).current = element;
					}
				}
				containerRef.current = element;
			}}>
			<RequestedBy requestedBy={request.receiverId} onBehalf={request.receiverId !== request.creatorId} />
			<RequestPermissions permissionsGrouping={permissionsGrouping} />
			<RequestedDuration duration={request.duration} fixedWidth />
			<div className={classNames(classes.approversJustification, { [classes.withApprovers]: withApprovers })}>
				<RequestJustification
					className={classes.grow}
					justification={request.comments?.first()?.content || ""}
					ticketingIntegrationTicket={request.ticketingIntegrationTicket}
				/>
				{withApprovers ? <RequestApprovers request={request as Require<TicketModel, "approvalRequests">} /> : null}
			</div>
			<RequestSummary
				toApprove={hasApproverActions}
				showStatus={props.type === "past" || !type || request.status === "waitingForIT"}
				request={request}
				actions={actions}
			/>
		</div>
	);
};

const memoizedComponent = React.memo(RequestBar);
export { memoizedComponent as RequestBar };
