import { useCallback, useMemo, useState } from "react";
import { getIntegrations } from "api/integrations";
import { usePagination } from "hooks/usePagination";
import { getIntegrationResources } from "api/integrationResources";
import { getIntegrationResourceRoles } from "api/integrationResourceRoles";
import { useSortState } from "hooks/useSortState";
import type { IntegrationModel } from "models/IntegrationModel";
import type { IntegrationResourceModel } from "models/IntegrationResourceModel";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { IPaginatedSearchOptions } from "utils/searchUtils";
import type { Require } from "types/utilTypes";
import type { IPaginationResponse } from "utils/pagination";
import type { TBulkActionModels } from "./types";
import type { ISortOptions } from "types/pagination";
import type { IFilter } from "types/filters";

const toggleSelection =
	<T extends TBulkActionModels>(item: T) =>
	(currentItems: T[]) => {
		if (currentItems.find(currentItem => currentItem.id === item.id)) {
			return currentItems.filter(currentItem => currentItem.id !== item.id);
		}
		return currentItems.concat(item);
	};

type TBulkActionSelection<T extends TBulkActionModels> = {
	anySelected: boolean;
	excludedItems: T[];
	isAllSelected: boolean;
	resetSelection: () => void;
	selectAll: (selection: boolean) => void;
	getSelectedAmount: (totalItems?: number, remoteUpdate?: boolean) => number;
	selectedItems: T[];
	toggleItemSelection: (item: T) => void;
	toggleSelectAll: () => void;
};

export const useSelection = <T extends TBulkActionModels>(): TBulkActionSelection<T> => {
	const [selectedItems, setSelectedItems] = useState<T[]>([]);
	const [excludedItems, setExcludedItems] = useState<T[]>([]);
	const [isAllSelected, setIsAllSelected] = useState(false);

	const resetSelection = useCallback(() => {
		setSelectedItems([]);
		setExcludedItems([]);
		setIsAllSelected(false);
	}, []);

	const selectAll = useCallback((selection: boolean) => {
		setSelectedItems([]);
		setExcludedItems([]);
		setIsAllSelected(selection);
	}, []);

	const toggleSelectAll = useCallback(() => {
		setSelectedItems([]);
		setExcludedItems([]);
		setIsAllSelected(current => !current);
	}, []);

	const toggleItemSelection = useCallback(
		(item: T) => {
			if (isAllSelected) {
				setExcludedItems(toggleSelection(item));
			} else {
				setSelectedItems(toggleSelection(item));
			}
		},
		[isAllSelected]
	);

	const getSelectedAmount = useCallback(
		(totalItems?: number, remoteEdit = false) => {
			if (remoteEdit && totalItems) return totalItems;
			if (isAllSelected) {
				return totalItems ? totalItems - excludedItems.length : 0;
			}
			return selectedItems.length;
		},
		[excludedItems.length, isAllSelected, selectedItems.length]
	);

	const anySelected = selectedItems.length > 0 || isAllSelected;

	return {
		anySelected,
		excludedItems,
		isAllSelected,
		resetSelection,
		selectAll,
		getSelectedAmount,
		selectedItems,
		toggleItemSelection,
		toggleSelectAll
	};
};

type TTableProps<T extends TBulkActionModels> = {
	isLoading: boolean;
	clearData: () => void;
	getPage: (page: number) => void;
	items: T[];
	setPartialItem: (item: Partial<T> & { id: string }) => void;
	perPage: number;
	remoteTotalItems: number;
	totalItems: number;
};

const PER_PAGE = 20;
const MAX_RESULTS = 1000;

const getTotalResults = (lastPageNumber: number, totalResults: number) => {
	const normalizedLastPageNumber = lastPageNumber || 1;
	if (totalResults > MAX_RESULTS && normalizedLastPageNumber * PER_PAGE >= MAX_RESULTS) return MAX_RESULTS;
	if (totalResults < MAX_RESULTS && normalizedLastPageNumber * PER_PAGE >= totalResults) return totalResults;
	return normalizedLastPageNumber * PER_PAGE + 1;
};

export const useIntegrationsTable = (filters: IFilter[], sort?: ISortOptions): TTableProps<IntegrationModel> => {
	const fetchIntegrations = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return getIntegrations({
				page: paginationOptions.pagination?.page,
				perPage: paginationOptions.pagination!.perPage!,
				order: paginationOptions.sort?.order,
				sortFields: paginationOptions.sort?.sortFields,
				filters
			});
		},
		[filters]
	);

	const { items, totalResults, getPage, lastPageNumber, clearData, setPartialItem, isLoading } = usePagination({
		fetchItems: fetchIntegrations,
		perPage: PER_PAGE,
		sortFields: sort?.sortFields || "name",
		sortOrder: sort?.order || "ASC"
	});

	return {
		isLoading,
		clearData,
		getPage,
		items: items?.toArray() || [],
		perPage: PER_PAGE,
		remoteTotalItems: totalResults,
		setPartialItem,
		totalItems: getTotalResults(lastPageNumber, totalResults)
	};
};

export const useIntegrationResourcesTable = (
	filters: IFilter[],
	sort?: ISortOptions
): TTableProps<IntegrationResourceModel> => {
	const fetchResources = useCallback(
		async (paginationOptions: IPaginatedSearchOptions) => {
			return getIntegrationResources({
				page: paginationOptions.pagination?.page,
				perPage: paginationOptions.pagination?.perPage,
				order: paginationOptions.sort?.order,
				sortFields: paginationOptions.sort?.sortFields,
				filters
			});
		},
		[filters]
	);

	const { items, totalResults, getPage, lastPageNumber, clearData, setPartialItem, isLoading } = usePagination({
		fetchItems: fetchResources,
		perPage: PER_PAGE,
		sortFields: sort?.sortFields || "integrationName",
		sortOrder: sort?.order || "ASC"
	});

	return {
		isLoading,
		clearData,
		getPage,
		items: items?.toArray() || [],
		perPage: PER_PAGE,
		remoteTotalItems: totalResults,
		setPartialItem: setPartialItem as (item: Partial<IntegrationResourceModel> & { id: string }) => void,
		totalItems: getTotalResults(lastPageNumber, totalResults)
	};
};

export const useIntegrationResourceRolesTable = (
	filters: IFilter[],
	sort?: ISortOptions
): TTableProps<Require<IntegrationResourceRoleModel, "integrationResource">> => {
	const fetchRoles = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			return getIntegrationResourceRoles({
				page: paginationOptions.pagination?.page,
				perPage: paginationOptions.pagination?.perPage,
				order: paginationOptions.sort?.order,
				sortFields: paginationOptions.sort?.sortFields,
				filters
			}) as Promise<IPaginationResponse<Require<IntegrationResourceRoleModel, "integrationResource">>>;
		},
		[filters]
	);

	const { items, totalResults, getPage, lastPageNumber, clearData, setPartialItem, isLoading } = usePagination({
		fetchItems: fetchRoles,
		perPage: PER_PAGE,
		sortFields: sort?.sortFields || "integrationName",
		sortOrder: sort?.order || "ASC"
	});

	return {
		isLoading,
		clearData,
		getPage,
		items: items?.toArray() || [],
		perPage: PER_PAGE,
		remoteTotalItems: totalResults,
		setPartialItem,
		totalItems: getTotalResults(lastPageNumber, totalResults)
	};
};

export const useBulkActionSort = () => {
	const integrationSortState = useSortState({ defaultSortOrder: "ASC", defaultSortField: "name" });
	const resourceSortState = useSortState({
		defaultSortOrder: "ASC",
		defaultSortField: "integrationName"
	});
	const roleSortState = useSortState({ defaultSortOrder: "ASC", defaultSortField: "integrationName" });

	return useMemo(
		() => ({
			integrationSortState,
			resourceSortState,
			roleSortState
		}),
		[integrationSortState, resourceSortState, roleSortState]
	);
};
