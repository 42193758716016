import { CancelCircleIcon } from "components/ui/Icons/CancelCircleIcon";
import { DeniedIcon } from "components/ui/Icons/DeniedIcon";
import { ErrorIcon } from "components/ui/Icons/ErrorIcon";
import { ExpiredIcon } from "components/ui/Icons/ExpiredIcon";
import { GrantedIcon } from "components/ui/Icons/GrantedIcon";
import { ManualApprovalIcon } from "components/ui/Icons/ManualApprovalIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import type { TTicketPermissionStatus } from "models/TicketPermissionModel";
import type { TTicketStatus } from "models/TicketModel";

export const TICKET_STATUS_ICON = new Map<TTicketStatus, IconComponent>([
	["approved", GrantedIcon],
	["cancelled", CancelCircleIcon],
	["failed", ErrorIcon],
	["granted", GrantedIcon],
	["permissionInProgress", PendingIcon],
	["rejected", DeniedIcon],
	["revoked", ExpiredIcon],
	["waitingForApproval", PendingIcon],
	["waitingForIT", ManualApprovalIcon]
]);

export const TICKET_PERMISSION_STATUS_ICON = new Map<TTicketPermissionStatus, IconComponent>([
	["failed", ErrorIcon],
	["granted", GrantedIcon],
	["revoked", CloseCircleIcon],
	["pending", PendingIcon]
]);

export const FAILURE_STATUSES = new Set<TTicketStatus>(["cancelled", "failed", "rejected"]);
export const SUCCESS_STATUSES = new Set<TTicketStatus>(["approved", "granted"]);
