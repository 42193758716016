import classNames from "classnames";
import _groupBy from "lodash/groupBy";
import { useControlled } from "hooks/useControlled";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { SAME_WIDTH_MODIFIER, useTooltip } from "hooks/useTooltip";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { cleanString, getOptionKey, getSuffix, sortOptions, type TOptionRenderer } from "utils/ui/select";
import uniqueId from "lodash/uniqueId";
import { basicSort } from "utils/sortUtils";
import { useDeepCompareEffect } from "hooks/useDeepCompareEffect";
import { Input, type IBaseInputProps } from "components/ui/Input";
import { notEmpty } from "utils/comparison";
import { SelectItemList } from "../SelectItemList";
import { useStyles } from "./styles";
import { Chips, type TChipType } from "./components/Chips";
import type { TInputSize } from "../fieldHelpers/types";
import type { THiddenChipType } from "../chips/HiddenChip";

export type TTargetValue = { target: { value: string } };

export interface IRenderChipParams<T> {
	componentKey: string;
	className?: string;
	noBorder?: boolean;
	onClick?: () => void;
	onRemove?: () => void;
	option: T;
	stretch?: boolean;
}

interface IProps<T> {
	chipType?: TChipType;
	chipsLimit?: number; // default: 1. limit the number of chips shown
	chipsPrefix?: IconComponent;
	debug?: boolean; // default: false. set to true to make select stay open (development only).
	defaultValue?: T[]; // default: null. default selected value.
	description?: string; // default: null. description to display under the label
	disabled?: boolean; // default: false. set to true to disable the select.
	errors?: string[]; // default: null. array of error messages.
	filter?: ((options: T[], inputValue: string) => T[]) | null; // default: by label. set the function you want to use to filter options by, use null for no filtering.
	getIconForGroup?: (groupName: string) => IconComponent | undefined;
	getOptionKey?: (option: T) => string; // default: undefined, try get label. how to get key by option.
	getOptionLabel: (option: T) => string; // how to get label by option. null returns empty string
	getQuery?: (value: string) => string;
	groupBy?: (option: T) => string; // default: null. set the function you want to use to group options by.
	hideOptionsList?: boolean; // default false
	inputClassName?: string;
	inputValue?: string; // default: undefined. set input value from props (Controlled)
	isOptionDisabled?: (option: T) => boolean; // default: false, set the function you want to use to disable options.
	isOptionEqualToValue?: (option: T, value: T) => boolean; // default: ===, equality comparator for options.
	label?: React.ReactNode; // default: "". label for select.
	labelIcon?: React.ReactNode | IconComponent; // default: undefined. icon to display in label.
	labelInfo?: React.ReactNode; // default: undefined. info to display in label.
	limit?: number; // default: 30. limit the number of options displayed.
	limitChipType?: THiddenChipType;
	loading?: boolean; // default: false. show loading indicator.
	multiLine?: boolean; // default: false. chip should be multiLine
	noCollapse?: boolean; // default: false. set to true to disable collapsing of chips.
	noResultsText?: string; // default: "No options found". text to display when no results found.
	onChange?: (value: T[] | null) => void; // default: undefined. callback on value change.
	onFocus?: () => void;
	onInputChange?: (event: (React.ChangeEvent<HTMLInputElement> & TTargetValue) | TTargetValue) => void; // default: undefined. callback on input value change.
	options: T[]; // required. options to display.
	placeholder?: string; // default: "". placeholder for the select.
	renderChip?: (params: IRenderChipParams<T>) => JSX.Element; // default: null. render function for each chip.
	renderOption: TOptionRenderer<NoInfer<T>>;
	required?: boolean; // default: false. set to true to make input required.
	size?: TInputSize; // default large
	sort?: ((options: T[]) => T[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	suffix?: JSX.Element; // default: undefined. icon to show after input.
	validators?: ((value: string) => string | null)[];
	value?: T[] | null; // default: undefined. set value from props (Controlled).
	variant?: "regular" | "table"; // default: "box". variant of select.
}

function MultipleSelect<T>(props: TProps<IProps<T> & Omit<IBaseInputProps, keyof IProps<T>>>) {
	const {
		chipType,
		chipsLimit = 1,
		chipsPrefix: prefix,
		className,
		debug,
		defaultValue = null,
		description = null,
		disabled = false,
		errors = null,
		filter,
		getIconForGroup,
		getOptionKey: propGetOptionKey = getOptionKey,
		getOptionLabel: propGetOptionLabel,
		getQuery = option => option,
		groupBy,
		hideOptionsList = false,
		id: propId,
		inputClassName,
		inputValue: propInputValue,
		isOptionDisabled: propIsOptionDisabled,
		isOptionEqualToValue = (option, currentValue) => option === currentValue,
		label = "",
		labelIcon,
		labelInfo,
		limit = 30,
		limitChipType,
		loading = false,
		multiLine = false,
		noCollapse = false,
		noResultsText,
		onChange: propOnChange,
		onFocus: propOnFocus,
		onInputChange: propOnInputChange,
		options: propOptions,
		placeholder,
		renderChip: propRenderChip,
		renderOption,
		required = false,
		size = "large",
		sort = sortOptions,
		suffix: propSuffix,
		validators = [],
		value: propValue,
		variant = "regular"
	} = props;

	const [id] = useState(() => propId || uniqueId());
	const selectRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const inputContainerRef = useRef<HTMLDivElement>(null);
	const [value, setValue] = useControlled<T[] | null>({
		controlled: propValue,
		default: defaultValue
	});
	const [inputValue, setInputValue] = useControlled<string>({ controlled: propInputValue, default: "" });
	const [open, setOpen] = useState(false);
	const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
	const [isDirty, setIsDirty] = useState(false);
	const [isTouched, setIsTouched] = useState(false);
	const [isFocused, setIsFocused] = useState(false);
	const [errorMessages, setErrorMessages] = useControlled<string[] | null>({
		controlled: errors,
		default: undefined
	});
	const classes = useStyles();
	const {
		visible,
		setTooltipRef,
		getTooltipProps,
		setTriggerRef,
		popperProps: { update: updatePopper }
	} = useTooltip({
		visible: open,
		offset: [0, 6],
		placement: "bottom",
		popperOptions: {
			modifiers: [SAME_WIDTH_MODIFIER]
		}
	});

	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => {
		if (!debug) {
			setOpen(false);
			setHighlightedIndex(value ? -1 : 0);
		}
	}, [debug, value]);
	useOnClickOutside(selectRef, handleClose);

	const options = useMemo(() => {
		const newOptions = sort !== null ? (sort(propOptions) as T[]) || propOptions : propOptions;

		if (groupBy) {
			const groups = _groupBy(newOptions, groupBy);
			const groupMap = new Map(Object.entries(groups));
			const sortedGroupNames = basicSort(Object.keys(groups), []);
			return sortedGroupNames.flatMap(groupName => groupMap.get(groupName) || []);
		}

		return newOptions;
	}, [groupBy, propOptions, sort]);

	const getOptionLabel = useCallback(
		(option: T) => {
			if (!option) return "";
			const optionLabel = propGetOptionLabel ? propGetOptionLabel(option) : "";
			return typeof optionLabel === "string" ? optionLabel : "";
		},
		[propGetOptionLabel]
	);

	const resetInputValue = useCallback(
		(event?: React.SyntheticEvent | null) => {
			if (inputValue === "") {
				return;
			}
			setInputValue("");
			if (propOnInputChange) {
				propOnInputChange(event ? { ...event, target: { ...event.target, value: "" } } : { target: { value: "" } });
			}
			updatePopper?.();
		},
		[inputValue, propOnInputChange, setInputValue, updatePopper]
	);

	const validate = useCallback(
		(toValidate: string) => {
			return validators?.map(validator => validator(toValidate) || "").filter(validator => validator !== "");
		},
		[validators]
	);

	const handleValue = useCallback(
		(newValue: T[] | null) => {
			if (value?.length === newValue?.length && value?.every((val, i) => val === newValue?.[Number(i)])) {
				return;
			}
			if (propOnChange) {
				propOnChange(newValue);
			}
			setValue(newValue);
		},
		[propOnChange, setValue, value]
	);

	const selectNewValue = useCallback(
		(event: React.SyntheticEvent, newValue: T) => {
			const newValueArray = value?.slice() || [];
			const itemIndex = newValueArray.findIndex(valueItem => isOptionEqualToValue(newValue, valueItem));

			if (itemIndex === -1) {
				newValueArray.push(newValue);
			} else {
				newValueArray.splice(itemIndex, 1);
			}
			resetInputValue(event);
			handleValue(newValueArray);
			handleClose();
		},
		[handleClose, handleValue, isOptionEqualToValue, resetInputValue, value]
	);

	const handleFocus = useCallback(
		(event?: React.FocusEvent<HTMLInputElement>) => {
			if (propOnFocus && event) {
				propOnFocus();
			}

			if (open) return;

			setIsFocused(true);
			setIsTouched(true);

			handleOpen();
		},
		[propOnFocus, open, handleOpen]
	);

	const handleBlur = useCallback(
		(event: React.SyntheticEvent) => {
			resetInputValue(event);
			setIsFocused(false);

			if (!open) return;

			handleClose();
		},
		[handleClose, open, resetInputValue]
	);

	const handleInputChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const newInputValue = event.target.value;
			if (newInputValue !== inputValue) {
				setInputValue(newInputValue);
				if (propOnInputChange) {
					propOnInputChange(event);
				}
			}

			const validationErrors = validate(newInputValue);
			setIsDirty(true);
			setErrorMessages(validationErrors ?? null);

			if (newInputValue) {
				handleOpen();
			}
			updatePopper?.();
		},
		[handleOpen, inputValue, propOnInputChange, setErrorMessages, setInputValue, validate, updatePopper]
	);

	const handleMouseDown = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			const target = event.target as HTMLElement;
			if (target !== selectRef.current && target !== inputRef.current) {
				event.preventDefault();
			}

			if (target === inputContainerRef.current) {
				handleFocus();
			}
		},
		[handleFocus]
	);

	const handleInputMouseDown = useCallback(() => {
		if (inputValue === "" || !open) {
			if (open) {
				handleClose();
			} else {
				handleOpen();
			}
		}
	}, [handleClose, handleOpen, inputValue, open]);

	const onClick = useCallback(() => {
		inputRef.current?.focus();
	}, []);

	const handleClear = useCallback(
		(event: React.SyntheticEvent) => {
			handleValue([]);
			resetInputValue(event);
			event.stopPropagation();
		},
		[handleValue, resetInputValue]
	);

	const filteredOptions = useMemo(() => {
		if (filter === null) {
			return options.slice(0, limit);
		}
		const cleanInputValue = inputValue ? cleanString(getQuery(inputValue)) : inputValue;
		if (cleanInputValue && cleanInputValue.length > 0) {
			if (filter) {
				return filter(options, cleanInputValue).slice(0, limit);
			}
			if (groupBy) {
				return options
					.filter(
						option =>
							cleanString(groupBy(option)).includes(cleanInputValue) ||
							cleanString(getOptionLabel(option)).includes(cleanInputValue)
					)
					.slice(0, limit);
			}
			return options.filter(option => cleanString(getOptionLabel(option)).includes(cleanInputValue)).slice(0, limit);
		}
		return options?.slice(0, limit);
	}, [filter, getQuery, inputValue, options, limit, groupBy, getOptionLabel]);

	const onKeyDown = useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			if (event.key === "ArrowUp") {
				setHighlightedIndex(Math.max(highlightedIndex - 1, 0));
				event.preventDefault();
			} else if (event.key === "ArrowDown") {
				setHighlightedIndex(Math.min(highlightedIndex + 1, limit - 1, filteredOptions.length - 1));
				event.preventDefault();
			} else if (event.key === "Enter") {
				if (highlightedIndex === -1) {
					return;
				}
				const newValue = filteredOptions.at(highlightedIndex);
				if (newValue) {
					selectNewValue(event, newValue);
				}
			}
		},
		[highlightedIndex, limit, filteredOptions, selectNewValue]
	);

	const isOptionDisabled = useCallback(
		(option: T) => {
			if (propIsOptionDisabled) {
				return propIsOptionDisabled(option);
			}
			return false;
		},
		[propIsOptionDisabled]
	);

	const suffixIcon = useMemo(() => {
		const hasInputValue = inputValue ? inputValue.length > 0 : false;
		const hasValue = value ? value.length > 0 : false;
		const canClear = (hasInputValue || hasValue) && !required;
		return getSuffix({
			disabled,
			handleClear,
			handleClose,
			handleOpen,
			loading,
			open,
			showClear: canClear,
			suffixClassName: classes.suffix,
			suffixClearClassName: classes.suffixClear,
			suffix: propSuffix
		});
	}, [
		classes.suffix,
		classes.suffixClear,
		disabled,
		handleClear,
		handleClose,
		handleOpen,
		inputValue,
		loading,
		open,
		propSuffix,
		required,
		value
	]);

	const removeChip = useCallback(
		(option: T) => {
			const newValue = value?.filter(val => !isOptionEqualToValue(val, option)) || [];
			handleValue(newValue);
		},
		[handleValue, isOptionEqualToValue, value]
	);

	const chips = useMemo(
		() =>
			value && value.filter(notEmpty).length ? (
				<Chips
					ChipElement={propRenderChip}
					ChipPrefixIcon={prefix}
					getOptionLabel={getOptionLabel}
					limit={chipsLimit}
					limitChipType={limitChipType}
					multiLine={multiLine}
					noCollapse={noCollapse}
					onRemove={removeChip}
					selected
					size={size === "large" ? "medium" : "small"}
					type={chipType}
					values={value || null}
				/>
			) : null,
		[
			propRenderChip,
			prefix,
			getOptionLabel,
			chipsLimit,
			limitChipType,
			multiLine,
			noCollapse,
			removeChip,
			size,
			chipType,
			value
		]
	);

	// Using deep compare to block a case of same array values to trigger the hook
	useDeepCompareEffect(() => {
		resetInputValue(null);
	}, [value]);

	const SelectList = useMemo(() => {
		if (!visible || disabled || hideOptionsList) return null;
		return (
			<div
				ref={setTooltipRef}
				{...getTooltipProps()}
				className={classNames(classes.selectItemsContainer, classes.maxHeight)}>
				<SelectItemList
					filteredOptions={filteredOptions}
					getIconForGroup={getIconForGroup}
					getOptionKey={propGetOptionKey}
					getOptionLabel={getOptionLabel}
					groupBy={groupBy}
					hasMore={options.length > limit && filteredOptions.length === limit}
					inputValue={inputValue}
					isOptionEqualToValue={isOptionEqualToValue}
					noOptionsText={noResultsText}
					onSelect={selectNewValue}
					renderOption={renderOption}
					shouldDisableOption={isOptionDisabled}
					value={value}
				/>
			</div>
		);
	}, [
		classes.maxHeight,
		classes.selectItemsContainer,
		disabled,
		filteredOptions,
		getIconForGroup,
		getOptionLabel,
		getTooltipProps,
		groupBy,
		hideOptionsList,
		inputValue,
		isOptionDisabled,
		isOptionEqualToValue,
		limit,
		noResultsText,
		options.length,
		propGetOptionKey,
		renderOption,
		selectNewValue,
		setTooltipRef,
		value,
		visible
	]);

	return (
		<div
			className={classNames(classes.container, className)}
			ref={selectRef}
			onMouseDown={handleMouseDown}
			onClick={onClick}>
			<Input
				className={classes.input}
				description={description || undefined}
				dirty={isDirty}
				disabled={disabled}
				errors={errorMessages || undefined}
				focused={isFocused}
				id={id}
				innerRef={setTriggerRef}
				inputContainerClassName={classNames(classes.inputContainer, inputClassName)}
				inputRef={inputRef}
				isRequired={required}
				label={label}
				labelIcon={labelIcon}
				labelInfo={labelInfo}
				onBlur={handleBlur}
				onChange={handleInputChange}
				onFocus={handleFocus}
				onKeyDown={onKeyDown}
				onMouseDown={handleInputMouseDown}
				prefix={chips}
				placeholder={value?.length ? undefined : placeholder}
				size={size}
				suffix={suffixIcon}
				touched={isTouched}
				value={inputValue}
				variant={variant === "regular" ? "text" : "table"}
			/>
			{SelectList}
		</div>
	);
}

const MemoizedComponent = React.memo(MultipleSelect) as typeof MultipleSelect;

export { MemoizedComponent as MultipleSelect };
