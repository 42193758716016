import React, { useMemo } from "react";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const ResourceOption: TOptionComponent<IntegrationResourceModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const Icon = useMemo(() => {
		return option.integration?.imageUrl
			? getDynamicSizeIcon(<img src={option.integration?.imageUrl} />)
			: ResourcesIcon;
	}, [option.integration?.imageUrl]);

	return (
		<SelectOption
			PrefixIcon={Icon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant="body_reg" content={option.name} />
		</SelectOption>
	);
};

ResourceOption.isSelectOption = true;

export { ResourceOption };
