import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
	getGroups,
	getOptionKey as utilsGetOptionKey,
	type TOptionRenderer,
	type TRenderOptionProps
} from "utils/ui/select";
import { Typography } from "../Typography";
import { IconPrefix } from "../IconPrefix";
import { useStyles } from "./styles";

interface ISelectItemListProps<T> {
	checkSelectedOnEmptyValue?: boolean;
	filteredOptions: T[];
	getIconForGroup?: (groupName: string) => IconComponent | undefined;
	getOptionKey?: (option: T) => string;
	getOptionLabel?: (option: T) => string;
	groupBy?: (option: T) => string;
	hasMore: boolean;
	inputValue?: string | null;
	isOptionEqualToValue?: (option: T, value: T) => boolean;
	maxHeight?: number;
	noOptionsText?: string;
	onSelect: (event: React.SyntheticEvent, value: T) => void;
	renderOption: TOptionRenderer<T>;
	shouldDisableOption?: (value: T) => boolean;
	value?: T[] | T | null;
}

export function SelectItemList<T>(props: TProps<ISelectItemListProps<T>>) {
	const {
		checkSelectedOnEmptyValue = false,
		filteredOptions,
		getIconForGroup,
		getOptionKey: propGetOptionKey,
		getOptionLabel,
		groupBy,
		hasMore,
		inputValue,
		isOptionEqualToValue,
		maxHeight,
		noOptionsText = null,
		onSelect,
		renderOption,
		shouldDisableOption,
		value
	} = props;
	const classes = useStyles({ maxHeight });
	const { t } = useTranslation();
	const noOptions = noOptionsText || t("common.select.noOptionsFound");

	const getOptionKey = useCallback(
		(option: T) => {
			const key = propGetOptionKey ? propGetOptionKey(option) : utilsGetOptionKey(option, getOptionLabel);
			return key;
		},
		[getOptionLabel, propGetOptionKey]
	);

	const searchForMore = useMemo(
		() =>
			hasMore ? (
				<Typography className={classes.searchForMore} variant="body_reg">
					{t("common.select.searchForMore")}
				</Typography>
			) : null,
		[classes.searchForMore, hasMore, t]
	);

	const groups = useMemo(
		() => (groupBy && filteredOptions && filteredOptions.length ? getGroups(filteredOptions, groupBy) : null),
		[filteredOptions, groupBy]
	);

	const getIsSelected = useCallback(
		(option: T) => {
			const isEqualToValue =
				isOptionEqualToValue &&
				(checkSelectedOnEmptyValue || value) &&
				(Array.isArray(value)
					? value?.some(val => isOptionEqualToValue(option, val))
					: isOptionEqualToValue(option, value!));

			return option && !!isEqualToValue;
		},
		[checkSelectedOnEmptyValue, isOptionEqualToValue, value]
	);

	if (!filteredOptions?.length) {
		return <Typography className={classes.noOptions}>{noOptions}</Typography>;
	}

	if (groups) {
		const groupNames: string[] = [];
		for (const groupName of groups.keys()) {
			groupNames.push(groupName);
		}
		return (
			<div className={classes.maxHeight}>
				{groupNames.map(groupName => {
					const options = groups.get(groupName);
					const header = groupName && (groupName.endsWith(":") ? groupName.replace(":", "") : groupName);
					const Icon = getIconForGroup?.(groupName);
					return (
						<div key={header || "NO_GROUP"} className={classes.groupContainer}>
							{header ? (
								<IconPrefix Icon={Icon} content={header.replace(":", "")} semibold className={classes.groupLabel} />
							) : null}
							{options?.map(option => {
								const optionKey = getOptionKey(option);
								const disabled = shouldDisableOption ? shouldDisableOption(option) : false;
								const isSelected = getIsSelected(option);
								if ("isSelectOption" in renderOption && renderOption.isSelectOption) {
									const OptionComp = renderOption as FC<TRenderOptionProps<T>>;
									return (
										<OptionComp
											disabled={disabled}
											getTextContent={getOptionLabel}
											inputValue={inputValue}
											isSelected={isSelected}
											key={optionKey}
											onSelect={onSelect}
											option={option}
											optionKey={optionKey}
										/>
									);
								}
								return renderOption({
									disabled,
									getTextContent: getOptionLabel,
									inputValue,
									isSelected,
									onSelect,
									option,
									optionKey
								});
							})}
						</div>
					);
				})}
				{searchForMore}
			</div>
		);
	}

	return (
		<div className={classes.maxHeight}>
			{filteredOptions.map(option => {
				const optionKey = getOptionKey(option);
				const disabled = shouldDisableOption ? shouldDisableOption(option) : false;
				const isSelected = getIsSelected(option);
				if ("isSelectOption" in renderOption && renderOption.isSelectOption) {
					const OptionComp = renderOption as FC<TRenderOptionProps<T>>;
					return (
						<OptionComp
							disabled={disabled}
							getTextContent={getOptionLabel}
							inputValue={inputValue}
							isSelected={isSelected}
							key={optionKey}
							onSelect={onSelect}
							option={option}
							optionKey={optionKey}
						/>
					);
				}
				return renderOption({
					disabled,
					getTextContent: getOptionLabel,
					inputValue,
					isSelected,
					onSelect,
					option,
					optionKey
				});
			})}
			{searchForMore}
		</div>
	);
}
