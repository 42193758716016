import React, { useCallback, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { useTranslation } from "react-i18next";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Typography } from "components/ui/Typography";
import { Section } from "components/ui/Section";
import { BundleModel } from "models/BundleModel";
import { Input } from "components/ui/Input";
import { useBundles } from "hooks/useBundles";
import { DropdownSortButton } from "components/ui/DropdownSortButton";
import { useSortState } from "hooks/useSortState";
import { BundleTable } from "../BundleTable/BundleTable";
import { useStyles } from "./styles";

type TBundleTableSectionProps = {
	onEdit?: ((bundle: BundleModel) => void) | null;
	onRemove?: ((bundle: BundleModel) => void) | null;
};
const FIELDS_SORT_ORDER = ["bundle", "category", "description", "permissions", "approvalAlgorithm"] as const;
const SORT_FIELDS_TRANSLATION = {
	name: "bundle",
	category: "category",
	description: "description",
	permissions: "permissions",
	approvalWorkflow: "approvalAlgorithm"
};
const ASC = "ASC";
export const BundleTableSection: FC<TBundleTableSectionProps> = ({ className, innerRef, onRemove, onEdit }) => {
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const classes = useStyles();
	const bundles = useBundles();
	const sortState = useSortState({ defaultSortField: "name", defaultSortOrder: "ASC" });

	const isBundleMatchQuery = useCallback(
		(bundle: BundleModel) => {
			const lowerCasedQuery = query.toLowerCase();
			return (
				bundle.name.toLowerCase().includes(lowerCasedQuery) ||
				bundle.description?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.category?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.tags?.some(tag => tag.toLowerCase() === lowerCasedQuery) ||
				bundle.approvalAlgorithm?.name?.toLowerCase().includes(lowerCasedQuery) ||
				bundle.bundleItems?.some(
					bundleItem =>
						bundleItem.integrationResourceRole.integrationResource?.name.toLowerCase().includes(lowerCasedQuery) ||
						bundleItem.integrationResourceRole.name.toLowerCase().includes(lowerCasedQuery)
				)
			);
		},
		[query]
	);

	const getSortField = useCallback((bundle: BundleModel, sortField: string) => {
		switch (sortField) {
			case "name":
				return bundle.name;
			case "category":
				return bundle.category;
			case "description":
				return bundle.description;
			case "permissions":
				return bundle.bundleItems?.map(bundleItem => bundleItem.integrationResourceRole.name).join(", ");
			case "approvalWorkflow":
				return bundle.approvalAlgorithm?.name;
			default:
				return null;
		}
	}, []);

	const sortBundles = useCallback(
		(bundles: BundleModel[]) => {
			const sortedBundles = sortBy(bundles, bundle => getSortField(bundle, sortState.sortFields[0]) || bundle);
			return sortState.sortOrder === ASC ? sortedBundles : sortedBundles.reverse();
		},
		[getSortField, sortState.sortFields, sortState.sortOrder]
	);

	const bundleArray = useMemo(() => {
		const filteredBundles = bundles?.toList().filter(isBundleMatchQuery).toArray() || [];
		return sortBundles(filteredBundles);
	}, [bundles, isBundleMatchQuery, sortBundles]);

	const sortOptions = useMemo(
		() =>
			FIELDS_SORT_ORDER.map(label => ({
				label: t(`common.tables.sortFields.${label}`),
				value: Object.entries(SORT_FIELDS_TRANSLATION).find(([key, value]) => value === label)?.[0] as string
			})),
		[t]
	);

	const title = useMemo(() => {
		return (
			<>
				<Typography variant="body_med"> {t("pages.bundles.total")}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: bundles?.size || 0 })}
				</StaticChip>
			</>
		);
	}, [bundles?.size, t]);

	const actions = useMemo(() => {
		return (
			<>
				<Input
					className={classes.input}
					size="medium"
					placeholder={t("pages.users.search")}
					onValueChange={setQuery}
					value={query}
					variant="search"
				/>
				<DropdownSortButton sortState={sortState} options={sortOptions} buttonVariant="secondary" />
			</>
		);
	}, [classes.input, query, sortOptions, sortState, t]);

	return (
		<Section titleActions={actions} fullHeight title={title}>
			<BundleTable bundles={bundleArray} isLoading={!bundleArray} onEdit={onEdit} onRemove={onRemove} />
		</Section>
	);
};
