import { useState } from "react";
import constate from "constate";

const useSideNav = () => {
	const [hideSideNav, setHideSideNav] = useState(true);

	return { state: { hideSideNav }, actions: { setHideSideNav } };
};

export const [SideNavProvider, useSideNavContext] = constate(useSideNav, value => value);
