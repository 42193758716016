import { useEffect, useState } from "react";
import constate from "constate";
import { useSubscriber } from "hooks/useSubscriber";
import { TBulkActionErrorData, TBulkActionUpdateData, useAdminLiveUpdatesContext } from "./adminLiveUpdatesContext";

export type TBulkActionNotification =
	| { type: "progress"; currentBulkAction: TBulkActionUpdateData }
	| { type: "notInProgress" }
	| { type: "done" }
	| { type: "error" };

const useBulkActionsUpdates = () => {
	const [isBulkActionInProgress, setIsBulkActionInProgress] = useState(false);
	const [isBulkActionFailed, setIsBulkActionFailed] = useState(false);
	const { subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate } = useAdminLiveUpdatesContext();
	const { addSubscription, notify, removeSubscription } = useSubscriber<(data: TBulkActionNotification) => void>();

	useEffect(() => {
		function onBulkActionsUpdate(data: TBulkActionUpdateData | TBulkActionErrorData | null) {
			let inProgress = false;
			if (data) {
				if ("error" in data) {
					if (data.error) {
						notify({ type: "error" });
					}
				} else if (data.progressPercentage === 100) {
					notify({ type: "done" });
				} else {
					inProgress = true;
					notify({
						type: "progress",
						currentBulkAction: data
					});
				}
			} else {
				notify({ type: "notInProgress" });
			}
			setIsBulkActionInProgress(inProgress);
		}

		subscribeToBulkActionsUpdate(onBulkActionsUpdate);

		return () => {
			unsubscribeFromBulkActionsUpdate(onBulkActionsUpdate);
		};
	}, [isBulkActionInProgress, notify, subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate]);

	return {
		subscribeProgression: addSubscription,
		unsubscribeProgression: removeSubscription,
		isBulkActionInProgress,
		isBulkActionFailed,
		setIsBulkActionInProgress,
		setIsBulkActionFailed
	};
};

export const [BulkActionsUpdatesProvider, useBulkActionsUpdatesContext] = constate(useBulkActionsUpdates);
