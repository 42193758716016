import React, { useMemo } from "react";
import isNil from "lodash/isNil";
import { checkEquality as defaultCheckEquality } from "utils/ui/select";
import { BasicCard } from "components/ui/BasicCard";
import { getUniqueKey } from "utils/ui/getUniqueKey";
import { FilterHeader } from "../FilterHeader";
import { useStyles } from "./styles";
import type { TSingleChoiceFilterProps } from "../../types";

export function SingleChoiceFilter<T>({
	className,
	innerRef,
	title,
	onReset,
	onRemoveFilter,
	options,
	renderOption,
	selected,
	onOptionSelect,
	checkEquality = defaultCheckEquality
}: TProps<TSingleChoiceFilterProps<T>>) {
	const classes = useStyles();

	const renderedOptions = useMemo(() => {
		return options.map((option, index) => {
			const isSelected = checkEquality(option, selected);
			return {
				isSelected,
				key: getUniqueKey(option, index.toString()),
				onClick: () => onOptionSelect(option),
				optionElement: renderOption(option, isSelected)
			};
		});
	}, [options, checkEquality, selected, renderOption, onOptionSelect]);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={!isNil(selected)}
			/>
			<div className={classes.content}>
				{renderedOptions.map(option => (
					<BasicCard size="medium" key={option.key} selected={option.isSelected} onClick={option.onClick}>
						{option.optionElement}
					</BasicCard>
				))}
			</div>
		</>
	);
}
