import { TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS } from "components/ui/Tooltip";
import React, { useCallback, useMemo, useState } from "react";
import { Typography } from "components/ui/legacy/Typography";
import { useTooltip } from "hooks/useTooltip";
import { Input } from "components/ui/Input";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import { createPortal } from "react-dom";
import classNames from "classnames";
import { sortOptions } from "utils/ui/select";
import { useStyles } from "./styles";
import type { Placement } from "@popperjs/core";

export interface IFloatingSelectOption {
	label: string;
	value: string;
}
interface IProps {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children: React.ReactElement<any & { innerRef?: React.Ref<any> }, any>;
	filter?: boolean;
	onClose: () => void;
	onSelect: (value: string) => void;
	onInputChange?: (value: string) => void;
	inputValue?: string;
	open: boolean;
	options: IFloatingSelectOption[];
	position?: Placement;
	placeholder?: string;
	optionsClassName?: string;
	sort?: ((options: IFloatingSelectOption[]) => IFloatingSelectOption[]) | null; // default: undefined. set undefined for default sort. set to null if the options need no sort. set to function if options need to be sorted by the function.
	renderOption: (
		option: { label: string; value: string },
		props: { key: string; onClick: (event: React.MouseEvent) => void }
	) => JSX.Element;
}

export const FloatingSelect: FC<IProps> = ({
	children,
	filter,
	onClose,
	onSelect,
	onInputChange,
	inputValue,
	open,
	options,
	position = "auto",
	placeholder,
	renderOption,
	sort = sortOptions,
	className,
	optionsClassName
}) => {
	const useTooltipProps = useMemo(() => ({ visible: open, placement: position }), [position, open]);
	const classes = useStyles();
	const { visible, setTooltipRef, tooltipProps, setTriggerRef, tooltipRef } = useTooltip(useTooltipProps);
	const childrenRef = useMemo(() => {
		return typeof children.type === "string" && TOOLTIP_AVAILABLE_TO_ATTACH_COMPONENTS.includes(children.type)
			? { ref: setTriggerRef }
			: { innerRef: setTriggerRef };
	}, [children, setTriggerRef]);

	useOnClickOutside(tooltipRef || undefined, onClose);

	const clonedChildren = useMemo(() => {
		return React.cloneElement(children, { ...children.props, ...childrenRef });
	}, [children, childrenRef]);

	const sortedOptions = useMemo(() => {
		if (sort !== null) {
			return (sort(options) as IFloatingSelectOption[]) || options;
		}
		return options;
	}, [options, sort]);

	return (
		<React.Fragment>
			{clonedChildren}
			{visible &&
				createPortal(
					<div ref={setTooltipRef} {...tooltipProps} className={classNames(classes.tooltipContainer, className)}>
						<SelectBlock
							className={optionsClassName}
							filter={filter}
							options={sortedOptions}
							onSelect={onSelect}
							onInputChange={onInputChange}
							inputValue={inputValue}
							placeholder={placeholder}
							renderOption={renderOption}
						/>
					</div>,
					document.body
				)}
		</React.Fragment>
	);
};

const SelectBlock: FC<
	Pick<IProps, "onSelect" | "onInputChange" | "inputValue" | "options" | "renderOption" | "filter" | "placeholder">
> = ({ filter, onSelect, onInputChange, inputValue, options, renderOption, placeholder, className }) => {
	const [filterValue, setFilterValue] = useState("");
	const classes = useStyles();

	const onFilterChange = useCallback(
		(value: string) => {
			setFilterValue(value);
			onInputChange?.(value);
		},
		[onInputChange]
	);
	const stopPropagation = useCallback((event: React.MouseEvent | React.FocusEvent) => event.stopPropagation(), []);
	const onClick = useCallback(
		(value: string) => (event: React.MouseEvent) => {
			stopPropagation(event);
			onSelect(value);
		},
		[onSelect, stopPropagation]
	);
	const filterOptions = useCallback(
		(option: { label: string; value: string }) => {
			if (!filterValue) return true;

			return option.label.toLowerCase().includes(filterValue.toLowerCase());
		},
		[filterValue]
	);

	const filteredOptions = useMemo(() => {
		return (
			options
				.filter(filterOptions)
				// too much can crush the page
				.slice(0, 200)
		);
	}, [options, filterOptions]);

	return (
		<Typography component="div" className={classNames(classes.select, className)} onClick={stopPropagation}>
			{filter && (
				<Input
					className={classes.filter}
					onFocus={stopPropagation}
					onValueChange={onFilterChange}
					placeholder={placeholder}
					value={inputValue}
					variant="search"
				/>
			)}
			<div className={classNames(classes.selectOptions, className)}>
				{filteredOptions.map(option => renderOption(option, { onClick: onClick(option.value), key: option.value }))}
			</div>
		</Typography>
	);
};
