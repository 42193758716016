import React, { useMemo } from "react";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { TSources, sourceIcons } from "utils/ui/sourceIcons";
import { requirePropertyOf } from "utils/security";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { useTranslation } from "react-i18next";
import { MaintainersIcon } from "components/ui/Icons/MaintainersIcon";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { Chip, TChipVariant, TChipSize } from "../Chip";
import { useStyles } from "./styles";
import type { TEntityOption } from "utils/entityOptionType";

interface IGroupChipProps {
	value: DirectoryGroupModel | OnCallIntegrationScheduleModel | TEntityOption;
	icon?: IconComponent;
	readonly?: boolean;
	size?: TChipSize;
	variant?: TChipVariant;
	selected?: boolean;
	onDelete?: () => void;
}

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);
const MAINTAINER_TEXT = "maintainer";
const OWNER_TEXT = "owner";

export const GroupChip: FC<IGroupChipProps> = ({
	innerRef,
	className,
	value,
	icon,
	size = "medium",
	readonly = false,
	selected = false,
	variant = "regular",
	onDelete
}) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const { displayName, GroupIcon } = useMemo(() => {
		const checkRegexForDisplayName = (displayName: string) => {
			const match = sourceRegex.exec(displayName);
			if (match) {
				const iconString = match[1] as TSources;
				const name = match[2];
				const Icon = requirePropertyOf(sourceIcons, iconString);
				const IconWithClass = getDynamicSizeIcon(
					<Icon className={size === "small" ? classes.smallIconSize : classes.regularIconSize} />
				);
				return { displayName: name, GroupIcon: IconWithClass };
			}
			return { displayName, GroupIcon: undefined };
		};
		if (value instanceof DirectoryGroupModel) {
			return checkRegexForDisplayName(value.name);
		} else if (value instanceof OnCallIntegrationScheduleModel) {
			return {
				displayName: value.name,
				GroupIcon: value.type ? ON_CALL_INTEGRATIONS_LOGOS.get(value.type) : undefined
			};
		}
		const { displayName, GroupIcon } = checkRegexForDisplayName(
			value.displayName || t("common.approvalFlow.deletedOnCallEntitySchedule")
		);
		return {
			displayName,
			GroupIcon:
				GroupIcon ?? (value.onCallScheduleType ? ON_CALL_INTEGRATIONS_LOGOS.get(value.onCallScheduleType) : undefined)
		};
	}, [classes.regularIconSize, classes.smallIconSize, size, value, t]);

	const iconType = useMemo(() => {
		if (!GroupIcon && displayName?.toLocaleLowerCase().includes(MAINTAINER_TEXT)) {
			return MaintainersIcon;
		} else if (!GroupIcon && displayName.toLocaleLowerCase().includes(OWNER_TEXT)) {
			return OwnerIcon;
		} else {
			return icon;
		}
	}, [GroupIcon, displayName, icon]);

	return (
		<Chip
			readonly={readonly}
			variant={variant}
			size={size}
			selected={selected}
			PrefixIcon={GroupIcon || iconType}
			className={className}
			innerRef={innerRef}
			onDelete={onDelete}>
			{displayName}
		</Chip>
	);
};
