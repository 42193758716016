import { useMemo } from "react";
import { usePageContext } from "context/pageContext";
import type { UserModel } from "models/UserModel";
import type { TFullTicket } from "../components/common/RequestDetails/types";
import type { TicketModel } from "models/TicketModel";
import type { TPageId } from "routes/route";

const RENEWAL_STATUSES = ["granted", "revoked"];

export const shouldAllowAdminApprove = (isAdmin: boolean, pageId: TPageId | null) =>
	isAdmin && pageId === "RequestsLog";
export const checkUserIsApprover = (ticket: TicketModel | TFullTicket, user: UserModel) =>
	!ticket.ticketApprovalResponses?.some(response => response.userId === user.id) &&
	ticket.approvalRequests?.some(
		request => request.approvalEntities?.some(entity => entity.approverIds.includes(user.id)) || false
	);

export const useTicketUserPermissions = (
	ticket: TicketModel | TFullTicket,
	user: UserModel | null,
	allowAdminActions = false
) => {
	const { scrollableId: pageId } = usePageContext();

	const permissions = useMemo(() => {
		if (!ticket || !user) return { hasApproverActions: false, allowAdminActions: false, isApprover: false };

		const isAdminApprove = allowAdminActions && shouldAllowAdminApprove(user?.isAdmin ?? false, pageId);
		const isApprover = checkUserIsApprover(ticket, user);
		const isReceiver = ticket.receiverId === user.id;
		const hasRetryActions = ticket.status === "failed" || ticket.status === "waitingForIT";

		return {
			isApprover,
			isReceiver,
			hasRetryActions,
			allowAdminActions: isAdminApprove,
			hasApproverActions: (isApprover || isAdminApprove) && ticket.status === "waitingForApproval",
			hasReceiverActions: isReceiver && RENEWAL_STATUSES.includes(ticket.status),
			hasCancel: ticket.status === "waitingForApproval" && isReceiver
		};
	}, [allowAdminActions, pageId, ticket, user]);

	return permissions;
};
