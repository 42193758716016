import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useIsOpenState } from "hooks/useIsOpenState";
import { DropdownButton } from "../DropdownButton";
import { Typography } from "../Typography";
import { IconButton } from "../IconButton";
import { ArrowUpIcon } from "../Icons/ArrowUpIcon";
import { ArrowDownIcon } from "../Icons/ArrowDownIcon";
import { ChevronDownIcon } from "../Icons/ChevronDownIcon";
import { useStyles } from "./styles";
import type { TSortOrder } from "types/pagination";
import type { TSortState } from "hooks/useSortState";

type TSortStateProps = {
	sortState: TSortState;
};

type TSortPartsProps = {
	sortOrder: TSortOrder;
	sortFields: string[];
	setSortState: (field: string, order?: TSortOrder) => void;
	isDirty: boolean;
};

type TProps = (TSortStateProps | TSortPartsProps) & {
	disabled?: boolean;
	buttonLabel?: string;
	buttonIcon?: React.ReactNode;
	options: { label: string; value: string }[];
	size?: "small" | "medium" | "large";
	buttonVariant?: "primary" | "secondary";
};

type TSortDropdownProps = {
	options: { label: string; value: string }[];
	selectedOption: string;
	currentSortOrder: TSortOrder;
	onSelect: (field: string) => void;
};

const SortDropdown: FC<TSortDropdownProps> = ({ currentSortOrder, options, selectedOption, onSelect }) => {
	const classes = useStyles();

	const renderedOptions = useMemo(() => {
		return options.map(option => {
			const onClick = () => onSelect(option.value);
			const isSelected = option.value === selectedOption;
			const isAscending = currentSortOrder === "ASC";
			return (
				<div
					key={option.value}
					className={classNames(classes.option, { [classes.selected]: isSelected })}
					onClick={onClick}>
					<Typography variant="text_reg">{option.label}</Typography>
					<IconButton className={classes.sortDirection} onClick={onClick}>
						{isSelected ? isAscending ? <ArrowUpIcon /> : <ArrowDownIcon /> : <ArrowDownIcon />}
					</IconButton>
				</div>
			);
		});
	}, [classes.option, classes.selected, classes.sortDirection, currentSortOrder, onSelect, options, selectedOption]);

	return <div className={classes.dropdown}>{renderedOptions}</div>;
};

export const DropdownSortButton: FC<TProps> = props => {
	const { className, buttonLabel, buttonIcon = <ChevronDownIcon />, options, size = "medium", buttonVariant } = props;
	const { sortOrder, sortFields, setSortState, isDirty } = "sortState" in props ? props.sortState : props;
	const { close, open, isOpen } = useIsOpenState();
	const { t } = useTranslation();

	const updateSort = useCallback(
		(field: string) => {
			if (sortFields.includes(field)) {
				setSortState(field, sortOrder === "ASC" ? "DESC" : "ASC");
			} else {
				setSortState(field, "DESC");
			}
			close();
		},
		[close, setSortState, sortFields, sortOrder]
	);

	const currentSelectedOption = useMemo(() => {
		return options.find(option => option.value === sortFields.at(0));
	}, [options, sortFields]);

	const selectedTextVariant = useMemo(() => {
		switch (size) {
			case "large":
				return "title_med";
			case "medium":
				return "text_reg";
			case "small":
				return "text_sm_reg";
		}
	}, [size]);

	if (!currentSelectedOption) return null;

	return (
		<DropdownButton
			disabled={props.disabled}
			size={size}
			suffix={buttonIcon}
			className={className}
			variant={buttonVariant}
			onClose={close}
			open={isOpen}
			onClick={open}
			position="bottom-start"
			dropdown={
				<SortDropdown
					currentSortOrder={sortOrder}
					options={options}
					selectedOption={currentSelectedOption.value}
					onSelect={updateSort}
				/>
			}>
			<>
				{buttonLabel || t("buttons.sort")}
				{isDirty ? <Typography variant={selectedTextVariant}>{`: ${currentSelectedOption.label}`}</Typography> : null}
			</>
		</DropdownButton>
	);
};
