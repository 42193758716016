import React, { useCallback, useMemo, useState } from "react";
import { ApprovalAlgorithm } from "components/common/ApprovalAlgorithm";
import { LoadingSpinner } from "components/ui/LoadingSpinner";
import { useApprovalFlows } from "hooks/useApprovalFlows";
import { useApprovalAlgorithmsList } from "hooks/useApprovalAlgorithms";
import { Typography } from "components/ui/legacy/Typography";
import { Button } from "components/ui/Button";
import { useTranslation } from "react-i18next";
import { FloatingSelect } from "components/common/FloatingSelect";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { sortOptions } from "utils/ui/select";
import { CommonAccordion } from "../CommonAccordion";
import { useStyles } from "./styles";

export const INHERIT_FROM_PARENT_ID = "INHERIT";

const sortApprovalAlgorithmOptions = (options: { label: string; value: string }[]) => {
	const optionsSorted = sortOptions(options) as { label: string; value: string }[];
	const inheritOptionIndex = optionsSorted.findIndex(option => option.value === INHERIT_FROM_PARENT_ID);
	if (inheritOptionIndex === -1) return optionsSorted;
	return [...optionsSorted.splice(inheritOptionIndex, 1), ...optionsSorted];
};

interface IApprovalAlgorithmNameProps {
	approvalAlgorithm: ApprovalAlgorithmModel;
}

const ApprovalAlgorithmName: FC<IApprovalAlgorithmNameProps> = ({ approvalAlgorithm }) => {
	return (
		<Typography relative prefixIcon={<WorkflowsIcon />}>
			{approvalAlgorithm.name}
		</Typography>
	);
};

interface IProps {
	approvalAlgorithmId: string;
	rounded?: boolean;
	onChangeAlgorithm?: (approvalAlgorithmId: string) => void;
	shouldExpand?: boolean;
	inheritOptionText?: string;
}

export const ApprovalAlgorithmBlock: FC<IProps> = ({
	approvalAlgorithmId,
	rounded,
	onChangeAlgorithm,
	inheritOptionText,
	shouldExpand = false
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectOpen, setSelectOpen] = useState(false);
	const toggleSelectOpen = useCallback((e: React.MouseEvent) => {
		e.stopPropagation();
		setSelectOpen(current => !current);
	}, []);

	const closeSelect = useCallback(() => setSelectOpen(false), []);

	const approvalFlows = useApprovalFlows();
	const approvalAlgorithms = useApprovalAlgorithmsList();

	const approvalAlgorithm = approvalAlgorithms?.find(approvalAlgorithm => approvalAlgorithm.id === approvalAlgorithmId);
	const approvalAlgorithmOptions = useMemo(() => {
		const approvalAlgorithmsArray = approvalAlgorithms?.toList()?.toArray();

		let options = [];
		inheritOptionText &&
			options.push(new ApprovalAlgorithmModel({ id: INHERIT_FROM_PARENT_ID, name: inheritOptionText }));
		approvalAlgorithmsArray?.length && (options = options.concat(approvalAlgorithmsArray));
		return options.map(approvalAlgorithm => ({
			value: approvalAlgorithm.id,
			label: approvalAlgorithm.name
		}));
	}, [approvalAlgorithms, inheritOptionText]);

	const renderOption = useCallback(
		(option: { value: string; label: string }, props: { onClick: (event: React.MouseEvent) => void; key: string }) => (
			<div className={classes.approvalAlgorithmOption} {...props}>
				<WorkflowsIcon /> {option.label}
			</div>
		),
		[classes.approvalAlgorithmOption]
	);
	const onChange = useCallback(
		async (approvalAlgorithmId: string) => {
			onChangeAlgorithm && (await onChangeAlgorithm(approvalAlgorithmId));
			closeSelect();
		},
		[closeSelect, onChangeAlgorithm]
	);

	if (!(approvalAlgorithm && approvalFlows)) {
		return <LoadingSpinner className={classes.spinner} />;
	}

	const title = onChangeAlgorithm ? (
		<div className={classes.title}>
			<ApprovalAlgorithmName approvalAlgorithm={approvalAlgorithm} />
			<FloatingSelect
				className={classes.floatingSelect}
				filter
				sort={sortApprovalAlgorithmOptions}
				onClose={closeSelect}
				onSelect={onChange}
				open={selectOpen}
				options={approvalAlgorithmOptions}
				optionsClassName={classes.list}
				position="bottom-start"
				placeholder={t("common.floatingSelect.searchWorkflow")}
				renderOption={renderOption}>
				<Button variant="secondary" size="medium" onClick={toggleSelectOpen}>
					{t("buttons.changeWorkflow")}
				</Button>
			</FloatingSelect>
		</div>
	) : (
		<ApprovalAlgorithmName approvalAlgorithm={approvalAlgorithm} />
	);

	return (
		<CommonAccordion rounded={rounded} title={title} defaultExpanded={shouldExpand}>
			<div className={classes.approvalAlgorithmContainer}>
				<ApprovalAlgorithm algorithm={approvalAlgorithm} approvalFlows={approvalFlows} contentOnly />
			</div>
		</CommonAccordion>
	);
};
