import React, { useMemo } from "react";
import { HeaderCellContent, TextCellContent } from "components/ui/VirtualTable/components";
import { useTranslation } from "react-i18next";
import { TColumn, VirtualTable } from "components/ui/VirtualTable";
import { BundleModel } from "models/BundleModel";
import { BundleIcon } from "components/ui/Icons/BundleIcon";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { DescriptionIcon } from "components/ui/Icons/DescriptionIcon";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { BundlePermissionsCell } from "./components/BundlePermissionsCell";
import { ActionsCell } from "./components/ActionsCell";
import { BundleNameCell } from "./components/BundleNameCell";

type TIntegrationLogsTableProps = {
	bundles: BundleModel[];
	isLoading: boolean;
	onEdit?: ((bundle: BundleModel) => void) | null;
	onRemove?: ((bundle: BundleModel) => void) | null;
};
const BUNDLES_PAGE_TRANSLATION_PREFIX = "pages.bundles.bundleTable";

const COLUMNS_WIDTHS = {
	bundleColumnSize: "minmax(152px, 1fr)",
	categoryColumnSize: "minmax(124px, 220px)",
	descriptionColumnSize: "minmax(172px, 1fr)",
	permissionsColumnSize: "158px",
	workflowColumnSize: "minmax(232px, 200px)",
	actionsColumnSize: "148px"
};
export const BundleTable: FC<TIntegrationLogsTableProps> = ({
	className,
	innerRef,
	bundles,
	isLoading,
	onEdit,
	onRemove
}) => {
	const { t } = useTranslation();

	const columns = useMemo(
		() =>
			[
				{
					renderCell: (row: BundleModel) => <BundleNameCell bundle={row} />,
					header: (
						<HeaderCellContent text={t(`${BUNDLES_PAGE_TRANSLATION_PREFIX}.columns.bundle`)} icon={<BundleIcon />} />
					),
					key: "bundle",
					width: COLUMNS_WIDTHS.bundleColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: BundleModel) => ({ text: row.category }),
					header: <HeaderCellContent text={t(`${BUNDLES_PAGE_TRANSLATION_PREFIX}.columns.category`)} />,
					key: "category",
					width: COLUMNS_WIDTHS.categoryColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: BundleModel) => ({ text: row.description }),
					header: (
						<HeaderCellContent
							text={t(`${BUNDLES_PAGE_TRANSLATION_PREFIX}.columns.description`)}
							icon={<DescriptionIcon />}
						/>
					),
					key: "description",
					width: COLUMNS_WIDTHS.descriptionColumnSize
				},
				{
					renderCell: (row: BundleModel) => <BundlePermissionsCell bundleItems={row.bundleItems} />,
					header: (
						<HeaderCellContent
							text={t(`${BUNDLES_PAGE_TRANSLATION_PREFIX}.columns.permissions`)}
							icon={<PermissionsIcon />}
						/>
					),
					key: "permissions",
					width: COLUMNS_WIDTHS.permissionsColumnSize
				},
				{
					renderCell: TextCellContent,
					getProps: (row: BundleModel) => ({ text: row.approvalAlgorithm?.name || "", icon: <WorkflowsIcon /> }),
					header: (
						<HeaderCellContent
							text={t(`${BUNDLES_PAGE_TRANSLATION_PREFIX}.columns.workflow`)}
							icon={<WorkflowsIcon />}
						/>
					),
					key: "workflow",
					width: COLUMNS_WIDTHS.workflowColumnSize
				},
				{
					renderCell: (row: BundleModel) => <ActionsCell bundle={row} onEdit={onEdit} onRemove={onRemove} />,
					key: "actions",
					width: COLUMNS_WIDTHS.actionsColumnSize
				}
			] as TColumn<BundleModel>[],
		[onEdit, onRemove, t]
	);

	return (
		<VirtualTable
			rows={bundles}
			totalRows={bundles.length}
			isLoading={isLoading}
			columns={columns}
			emptyTableMessage={t("pages.bundles.bundleTable.noBundles")}
		/>
	);
};
