import React, { useMemo } from "react";
import { TitleTooltip } from "components/ui/TitleTooltip";
import { StaticChip } from "components/ui/chips/StaticChip";
import { useTranslation } from "react-i18next";
import { PermissionsIcon } from "components/ui/Icons/PermissionsIcon";
import { Chip } from "components/ui/chips/Chip";
import { BundleItemModel } from "models/BundleItemModel";
import { List } from "immutable";
import { useIntegrations } from "hooks/useIntegrations";
import { IconPrefix } from "components/ui/IconPrefix";
import { PermissionsRoleBar } from "../PermissionsRoleBar";
import { useStyles } from "./styles";

type TProps = {
	bundleItems: List<BundleItemModel> | null;
};
const TOOLTIP_OFFSET = [-45, 6] as [number, number];
export const BundlePermissionsCell: FC<TProps> = ({ bundleItems }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const integrations = useIntegrations();

	const permissionsTooltipHeader = useMemo(() => {
		return (
			<div className={classes.tooltipTitle}>
				<IconPrefix Icon={PermissionsIcon} content={t("pages.auditLog.integrationLogs.columns.permissions")} />
				<StaticChip size="small" variant="regular">
					{t("number", { value: bundleItems?.size || 0 })}
				</StaticChip>
			</div>
		);
	}, [bundleItems?.size, classes.tooltipTitle, t]);

	const permissionsTooltipBody = useMemo(
		() =>
			bundleItems?.map(bundleItem => {
				const integration = integrations?.find(
					integration => integration.id === bundleItem.integrationResourceRole.integrationResource?.integrationId
				);
				const resource = bundleItem.integrationResourceRole.integrationResource;

				return (
					<PermissionsRoleBar
						key={bundleItem.id}
						integration={integration}
						resource={resource ?? undefined}
						role={bundleItem.integrationResourceRole}
					/>
				);
			}),
		[bundleItems, integrations]
	);

	return bundleItems && bundleItems?.size > 0 ? (
		<TitleTooltip
			className={classes.tooltipBodyContainer}
			header={permissionsTooltipHeader}
			body={permissionsTooltipBody}
			placement="bottom-end"
			offset={TOOLTIP_OFFSET}>
			<div className={classes.permissionChip}>
				<Chip PrefixIcon={PermissionsIcon} size="medium">
					{bundleItems.size}
				</Chip>
			</div>
		</TitleTooltip>
	) : null;
};
