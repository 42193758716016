import React, { useCallback, useMemo } from "react";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";
import { IRenderChipParams, MultipleSelect } from "components/ui/MultipleSelect";
import { GroupChip } from "components/ui/chips/GroupChip";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { useCompany } from "hooks/useCompany";
import { useRankedSort } from "hooks/useRankedSort";
import { OnCallIntegrationScheduleOption } from "components/ui/selectOptions/OnCallIntegrationScheduleOption";

const ANY_SCHEDULES_ID = "anySchedules";

const EMPTY_LABEL_TRANSLATION = "flow.noOnCalls";
interface IProps {
	chipClassName?: string;
	disabled?: boolean;
	label?: string;
	onChangeScheduleIds: (scheduleIds: string[] | null) => void;
	onChangeToAnySchedules: () => void;
	values: string[];
	isAnySchedules: boolean;
}

const getOptionLabel = ({ name }: OnCallIntegrationScheduleModel) => name;

const SORT_OPTIONS = {
	keys: [{ key: (item: OnCallIntegrationScheduleModel) => item.name, threshold: matchSorter.rankings.MATCHES }]
};

const renderChip = ({ option, onRemove }: IRenderChipParams<OnCallIntegrationScheduleModel>) => (
	<GroupChip selected variant="regular" value={option} onDelete={onRemove} />
);

const optionIsDisabled = (option: OnCallIntegrationScheduleModel) => option.isDeleted;

export const OnCallIntegrationSchedulesSelectInput: FC<IProps> = ({
	values,
	onChangeScheduleIds,
	onChangeToAnySchedules,
	className,
	label,
	disabled,
	isAnySchedules
}) => {
	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const { sort, onInputChange } = useRankedSort(SORT_OPTIONS);
	const company = useCompany();
	const { t } = useTranslation();
	const options = useMemo(() => {
		const schedules =
			onCallIntegrationSchedules
				?.valueSeq()
				.toArray()
				.filter(schedule => !schedule.isDeleted || values.includes(schedule.id)) || [];
		schedules.push(
			new OnCallIntegrationScheduleModel({
				id: ANY_SCHEDULES_ID,
				name: t("flow.anyOnCallIntegrationSchedulesOption"),
				onCallIntegrationId: ""
			})
		);
		return schedules;
	}, [onCallIntegrationSchedules, t, values]);

	const handleChange = useCallback(
		(newValues: OnCallIntegrationScheduleModel[] | null) => {
			if (newValues?.[newValues.length - 1]?.id === ANY_SCHEDULES_ID) {
				onChangeToAnySchedules();
				return;
			}
			onChangeScheduleIds(newValues?.map(({ id }) => id)?.filter(id => id !== ANY_SCHEDULES_ID) || null);
		},
		[onChangeToAnySchedules, onChangeScheduleIds]
	);

	const selectedOptions = useMemo(
		() =>
			options?.filter(option => (option.id === ANY_SCHEDULES_ID ? isAnySchedules : values.includes(option.id))) || [],
		[options, values, isAnySchedules]
	);

	if (!company?.integratedToOnCalls.size) {
		return null;
	}

	const loading = !onCallIntegrationSchedules;

	return (
		<MultipleSelect
			className={className}
			disabled={loading || disabled}
			getOptionLabel={getOptionLabel}
			isOptionDisabled={optionIsDisabled}
			label={label}
			limitChipType="group"
			loading={loading}
			onChange={handleChange}
			onInputChange={onInputChange}
			renderOption={OnCallIntegrationScheduleOption}
			options={options}
			placeholder={values.length === 0 ? t(EMPTY_LABEL_TRANSLATION) : undefined}
			renderChip={renderChip}
			sort={sort}
			value={selectedOptions}
		/>
	);
};
