import React from "react";
import { RoleIcon } from "components/ui/Icons/RoleIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { NoEditIcon } from "components/ui/Icons/NoEditIcon";
import { SelectOption } from "../SelectOption/SelectOption";
import type { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import type { TOptionComponent } from "utils/ui/select";

const RoleOption: TOptionComponent<IntegrationResourceRoleModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	return (
		<SelectOption
			PrefixIcon={!option.managed ? NoEditIcon : RoleIcon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant="body_reg" content={option.name} />
		</SelectOption>
	);
};

RoleOption.isSelectOption = true;

export { RoleOption };
