import React from "react";
import { OnCallIntegrationScheduleModel } from "models/OnCallIntegrationScheduleModel";
import { ON_CALL_INTEGRATIONS_LOGOS } from "utils/ui/onCall";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { useOnCallIntegrationSchedules } from "hooks/useOnCallIntegrationSchedules";
import classNames from "classnames";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";
import type { TEntityOption } from "utils/entityOptionType";
import type { TOptionComponentProps } from "utils/ui/select";

function OnCallIntegrationScheduleOption<T extends OnCallIntegrationScheduleModel | string | TEntityOption>({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const classes = useStyles();

	const isId = typeof option === "string";
	const isEntityOption = typeof option === "object" && "identifier" in option;

	const onCallScheduleId = isId ? option : isEntityOption ? option.identifier : null;

	const onCallIntegrationSchedules = useOnCallIntegrationSchedules();
	const currentOnCallIntegrationSchedule = onCallScheduleId
		? onCallIntegrationSchedules?.get(onCallScheduleId)
		: (option as OnCallIntegrationScheduleModel);

	const Icon =
		currentOnCallIntegrationSchedule?.type && ON_CALL_INTEGRATIONS_LOGOS.get(currentOnCallIntegrationSchedule.type);

	return (
		<SelectOption
			PrefixIcon={Icon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			{currentOnCallIntegrationSchedule ? (
				<TooltipOnOverflow
					textVariant="body_reg"
					content={currentOnCallIntegrationSchedule.name}
					className={classNames(classes.content, { deleted: currentOnCallIntegrationSchedule.isDeleted })}
				/>
			) : null}
		</SelectOption>
	);
}

OnCallIntegrationScheduleOption.isSelectOption = true;

export { OnCallIntegrationScheduleOption };
