import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { useUserEntitlementsResourceTypes } from "../../myPermissionsHooks";
import { useStyles } from "./styles";

type TProps = {
	accountId?: string;
	disabled?: boolean;
	integrationId: string | null;
	onChange: (resourceType: string | null) => void;
	value: string | null;
};

export const ResourceTypesSelect: FC<TProps> = ({
	accountId,
	className,
	disabled,
	innerRef,
	integrationId,
	onChange,
	value
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { resourceTypes, isLoading } = useUserEntitlementsResourceTypes(integrationId, accountId);
	const options = useMemo(() => resourceTypes ?? [], [resourceTypes]);

	const renderLabel = useCallback((option: string) => <div className={classes.text}>{option}</div>, [classes]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			innerRef={innerRef}
			label={t("common.resourceTypeSelect.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			renderOption={TextOption}
			options={options}
			placeholder={t("common.resourceTypeSelect.placeholder")}
			renderLabel={renderLabel}
			getOptionLabel={getLabel}
			size="medium"
			value={value}
		/>
	);
};
