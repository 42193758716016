import { EventEmitter } from "events";
import { useRef, useCallback } from "react";
import constate from "constate";
import { useLiveUpdate } from "hooks/useLiveUpdate";
import { BULK_ACTIONS_UPDATE_EVENT_NAME, UPDATES_ROOM_NAME } from "utils/websockets/constants";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";

export type TBulkActionErrorData = { error: true };
export type TBulkActionUpdateData = {
	model: "Integration" | "IntegrationResource" | "IntegrationResourceRole";
	progressPercentage: number;
	totalItems: number;
	updates: Record<string, unknown>;
};
export type TBulkActionsUpdateEvent = typeof BULK_ACTIONS_UPDATE_EVENT_NAME;

const useAdminLiveUpdates = () => {
	const { user } = useAuthenticatedUser();
	const eventEmitterRef = useRef(new EventEmitter());

	const onBulkActionsUpdate = useCallback((data: TBulkActionUpdateData | TBulkActionErrorData | null) => {
		eventEmitterRef.current.emit(BULK_ACTIONS_UPDATE_EVENT_NAME, data);
	}, []);

	const unsubscribeFromBulkActionsUpdate = useCallback(
		(callback: (data: TBulkActionUpdateData | TBulkActionErrorData | null) => void) => {
			eventEmitterRef.current.off(BULK_ACTIONS_UPDATE_EVENT_NAME, callback);
		},
		[]
	);

	const subscribeToBulkActionsUpdate = useCallback(
		(callback: (data: TBulkActionUpdateData | TBulkActionErrorData | null) => void) => {
			eventEmitterRef.current.on(BULK_ACTIONS_UPDATE_EVENT_NAME, callback);
		},
		[]
	);

	useLiveUpdate<TBulkActionsUpdateEvent, TBulkActionErrorData | TBulkActionUpdateData | null>({
		roomName: UPDATES_ROOM_NAME,
		eventName: BULK_ACTIONS_UPDATE_EVENT_NAME,
		handleEvent: onBulkActionsUpdate,
		shouldJoinRoom: user?.role === "admin"
	});

	return { subscribeToBulkActionsUpdate, unsubscribeFromBulkActionsUpdate };
};

export const [AdminLiveUpdatesProvider, useAdminLiveUpdatesContext] = constate(useAdminLiveUpdates);
