import React, { useMemo } from "react";
import { DirectoryGroupModel } from "models/DirectoryGroupModel";
import { useDirectoryGroup } from "hooks/useDirectoryGroup";
import { sourceIcons, TSources } from "utils/ui/sourceIcons";
import { requirePropertyOf } from "utils/security";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import classNames from "classnames";
import { SelectOption } from "../SelectOption/SelectOption";
import { useStyles } from "./styles";
import type { TEntityOption } from "utils/entityOptionType";
import type { TOptionComponentProps } from "utils/ui/select";

const sourceRegex = new RegExp(/\[([A-z]+)\] (.*)/);

function DirectoryGroupOption<T extends DirectoryGroupModel | TEntityOption | string>({
	disabled = false,
	innerRef,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const isEntity = typeof option === "object" && "identifier" in option;
	const isId = typeof option === "string";
	const id = isEntity ? option.identifier : isId ? option : null;
	const currentGroup = useDirectoryGroup(id || undefined);
	const remoteGroup = id && currentGroup ? currentGroup : (option as DirectoryGroupModel);

	const classes = useStyles();

	const { name, Icon } = useMemo(() => {
		const match = sourceRegex.exec(remoteGroup.name);
		if (!match) {
			return {
				name: remoteGroup.name,
				icon: null
			};
		}

		const iconString = match[1] as TSources;
		const name = match[2];
		const Icon = requirePropertyOf(sourceIcons, iconString);

		return {
			name,
			Icon
		};
	}, [remoteGroup]);

	return (
		<SelectOption onSelect={onSelect} isSelected={isSelected} disabled={disabled} value={option} key={optionKey}>
			{remoteGroup.email ? (
				<div className={classes.container} ref={innerRef}>
					{Icon && <Icon className={classes.iconWithEmail} />}
					<div className={classes.title}>
						<TooltipOnOverflow
							content={name}
							textVariant="body_med"
							className={classNames(classes.content, { deleted: remoteGroup.isDeleted })}
						/>

						<TooltipOnOverflow content={remoteGroup.email} textVariant="text_sm_reg" />
					</div>
				</div>
			) : (
				<div className={classes.container} ref={innerRef}>
					{Icon && <Icon className={classes.icon} />}
					<TooltipOnOverflow content={name} textVariant="body_med" className={classes.text} />
				</div>
			)}
		</SelectOption>
	);
}

DirectoryGroupOption.isSelectOption = true;

export { DirectoryGroupOption };
