import React, { useCallback, useMemo } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";
import { Select } from "components/ui/Select";
import { sortByName } from "utils/sortUtils";
import { RoleOption } from "components/ui/selectOptions/RoleOption";
import { useUserEntitlementsRoles } from "../../myPermissionsHooks";
import { useStyles } from "./styles";

type TProps = {
	accountId?: string;
	disabled?: boolean;
	onChange: (roleId: string | null) => void;
	resourceId: string | null;
	value: string | null;
};

const isEqual = (option: IntegrationResourceRoleModel, value: IntegrationResourceRoleModel) => option.id === value.id;
const getLabel = (option: IntegrationResourceRoleModel) => option.name;

export const RolesSelect: FC<TProps> = ({
	accountId,
	className,
	disabled,
	innerRef,
	onChange: propOnChange,
	resourceId,
	value: propValue
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const { roles, isLoading } = useUserEntitlementsRoles(resourceId, accountId);
	const options = useMemo(() => (roles ? roles.valueSeq().toArray() : []), [roles]);

	const renderLabel = useCallback(
		(option: IntegrationResourceRoleModel) => <div className={classes.text}>{option.name}</div>,
		[classes]
	);

	const onChange = useCallback(
		(value: IntegrationResourceRoleModel | null) => {
			propOnChange(value?.id ?? null);
		},
		[propOnChange]
	);

	const value = useMemo(() => {
		return roles?.get(propValue || "") ?? null;
	}, [roles, propValue]);

	return (
		<Select
			className={classNames(classes.container, className)}
			disabled={disabled}
			getOptionLabel={getLabel}
			innerRef={innerRef}
			isOptionEqualToValue={isEqual}
			label={t("common.roleSelectInput.label")}
			limit={20}
			loading={isLoading}
			onChange={onChange}
			renderOption={RoleOption}
			options={options}
			placeholder={t("common.roleSelectInput.placeholder")}
			renderLabel={renderLabel}
			size="medium"
			sort={sortByName}
			value={value}
		/>
	);
};
