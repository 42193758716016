import React, { useCallback, useMemo } from "react";
import { getUniqueKey } from "utils/ui/getUniqueKey";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { FilterExpressionRelation } from "../FilterExpressionRelation";
import { FilterHeader } from "../FilterHeader";
import { useGetOperatorLabel } from "../../useRenderOperatorLabel";
import { SelectRowEmptyState } from "../SelectRowEmptyState";
import { useStyles } from "./styles";
import type { TMultiSelectFilterProps } from "../../types";
import type { TFilterOperator } from "types/filters";

export function MultiSelectFilter<T>({
	className,
	emptyState,
	filter,
	getMoreOptions,
	getOperatorLabel: propGetOperatorLabel,
	getOptionLabel,
	getOptionKey,
	groupBy,
	innerRef,
	inputPlaceholder,
	isLoading,
	onOperatorSelect,
	onOptionSelect,
	onRemoveFilter,
	onReset,
	operators,
	options,
	relation = "or",
	optionRenderer,
	renderSelected,
	selected,
	selectedOperator,
	title
}: TProps<TMultiSelectFilterProps<T>>) {
	const classes = useStyles();
	const getOperatorLabel = useGetOperatorLabel();

	const selectedRow = useMemo(() => {
		if (!selected.length) {
			return <SelectRowEmptyState content={emptyState} />;
		}
		const selectedComponents = selected.map((item, index) => {
			return (
				<React.Fragment key={getUniqueKey(item, index.toString())}>
					{renderSelected(item)}
					{index !== selected.length - 1 ? <FilterExpressionRelation relation={relation} /> : null}
				</React.Fragment>
			);
		});
		return (
			<>
				{selectedComponents}
				<FilterExpressionRelation disabled relation={relation} />
			</>
		);
	}, [selected, relation, emptyState, renderSelected]);

	const handleOperatorChange = useCallback(
		(operator: TFilterOperator | null) => {
			if (operator) {
				onOperatorSelect(operator);
			}
		},
		[onOperatorSelect]
	);

	const handleValueChange = useCallback(
		(option: T | null) => {
			if (option) {
				onOptionSelect(option);
			}
		},
		[onOptionSelect]
	);

	const onInputChange = useCallback(
		(event: { target: { value: string } }) => {
			getMoreOptions?.(event.target.value);
		},
		[getMoreOptions]
	);

	const isOptionEqualToValue = useCallback(
		(option: T) =>
			selected.some(value => {
				return typeof value === "object" && typeof option === "object" && "id" in value! && "id" in option!
					? option.id === value.id
					: value === option;
			}),
		[selected]
	);

	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected.length > 0}
				inputs={
					<>
						<Select
							className={classes.operatorSelect}
							hideClear
							onChange={handleOperatorChange}
							getOptionLabel={propGetOperatorLabel ?? getOperatorLabel}
							options={operators}
							value={selectedOperator}
							renderOption={TextOption}
						/>
						<Select
							filter={filter ?? null}
							getOptionKey={getOptionKey}
							getOptionLabel={getOptionLabel || getLabel}
							groupBy={groupBy}
							hideClear
							isOptionEqualToValue={isOptionEqualToValue}
							loading={isLoading}
							onChange={handleValueChange}
							onInputChange={onInputChange}
							options={options}
							placeholder={inputPlaceholder}
							renderLabel={null}
							renderOption={optionRenderer}
							resetValueOnSelect
							sort={null}
							value={null}
						/>
					</>
				}
			/>
			<div className={classes.content}>{selectedRow}</div>
		</>
	);
}
