import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpression } from "components/ui/filters/FilterExpression";
import { IntegrationIdFilter } from "filters/integration";
import { IntegrationResourceIntegrationIdFilter } from "filters/integrationResource";
import { IntegrationResourceRoleIntegrationIdFilter } from "filters/integrationResourceRole";
import { useIntegrations } from "hooks/useIntegrations";
import { notEmpty } from "utils/comparison";
import { IntegrationChip } from "components/ui/chips/IntegrationChip";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { IntegrationOption } from "components/ui/selectOptions/IntegrationOption";
import { useFilterFormExpression } from "./filter.hooks";
import type { Constructor } from "types/utilTypes";
import type { TFilterOperator } from "types/filters";
import type { IntegrationModel } from "models/IntegrationModel";

type TIntegrationIdFilters =
	| IntegrationIdFilter
	| IntegrationResourceIntegrationIdFilter
	| IntegrationResourceRoleIntegrationIdFilter;

type TIntegrationIdFilterProps = {
	filter: TIntegrationIdFilters;
	onChange: (filter: TIntegrationIdFilters | undefined, isValid: boolean) => void;
};

function getFilter(filterName: TIntegrationIdFilters["name"]): Constructor<TIntegrationIdFilters> {
	switch (filterName) {
		case IntegrationIdFilter.filterName:
			return IntegrationIdFilter;
		case IntegrationResourceIntegrationIdFilter.filterName:
			return IntegrationResourceIntegrationIdFilter;
		default:
			return IntegrationResourceRoleIntegrationIdFilter;
	}
}

const isValidOperator = (operator: TFilterOperator): operator is TIntegrationIdFilters["operator"] =>
	operator === "is" || operator === "isNot";

const OPERATORS = ["is", "isNot"] as TFilterOperator[];

export const IntegrationIdFilterExpression: FC<TIntegrationIdFilterProps> = ({
	className,
	innerRef,
	filter,
	onChange
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.bulkActions.filters" });
	const [query, setQuery] = useState<string>();

	const { clearFilter, removeFilter } = useFilterFormExpression<TIntegrationIdFilters>({
		filterName: filter.name,
		onChange,
		getFilter
	});

	const integrations = useIntegrations();

	const integrationOptions = useMemo(() => {
		return (integrations?.toList().toArray() || []).filter(integration =>
			integration.name.toLowerCase().includes((query || "").toLowerCase())
		);
	}, [integrations, query]);

	const selectedIntegrations = useMemo(() => {
		if (!filter || !integrations) return [];
		return filter.value.map(id => integrations.get(id)).filter(notEmpty);
	}, [filter, integrations]);

	const onOperatorSelect = useCallback(
		(operator: TFilterOperator) => {
			if (!filter) return;
			if (!isValidOperator(operator)) return;
			onChange(filter.set("operator", operator), filter.value.length > 0);
		},
		[filter, onChange]
	);

	const onOptionSelect = useCallback(
		(option: IntegrationModel) => {
			if (!filter || !option) return;
			const currentValue = filter.value;
			const newValue = currentValue.includes(option.id)
				? currentValue.filter(id => id !== option.id)
				: [...currentValue, option.id];
			onChange(filter.set("value", newValue), newValue.length > 0);
		},
		[filter, onChange]
	);

	const renderSelected = useCallback(
		(option: IntegrationModel) => (
			<IntegrationChip size="large" integration={option} selected onDelete={() => onOptionSelect(option)} />
		),
		[onOptionSelect]
	);

	return (
		<FilterExpression
			className={className}
			emptyState={<FilterExpressionEmptyState text={t("values.emptyIntegrations")} Icon={IntegrationIcon} />}
			filter={null}
			getMoreOptions={setQuery}
			innerRef={innerRef}
			inputPlaceholder={t("placeholders.integrationId")}
			isLoading={!integrations}
			onOperatorSelect={onOperatorSelect}
			onOptionSelect={onOptionSelect}
			onRemoveFilter={removeFilter}
			onReset={clearFilter}
			operators={OPERATORS}
			optionRenderer={IntegrationOption}
			options={integrationOptions}
			renderSelected={renderSelected}
			selected={selectedIntegrations}
			selectedOperator={filter.operator}
			title={t(`title.${filter.name}`)}
			type="multiSelect"
		/>
	);
};
