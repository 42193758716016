import { List, Record } from "immutable";
import { TTicketDuration } from "../utils/durationsOptions";
import { TicketApprovalRequestModel } from "./TicketApprovalRequestModel";
import { TicketPermissionModel } from "./TicketPermissionModel";
import { TicketCommentModel } from "./TicketCommentModel";
import { TicketAuditLogModel } from "./auditLogs/TicketAuditLogModel";
import { TicketApprovalResponseModel } from "./TicketApprovalResponseModel";
import { TicketingIntegrationTicketModel } from "./TicketingIntegrationTicketModel";
import { TicketTargetModel } from "./TicketTargetModel";

enum TicketStatus {
	"approved" = "approved",
	"cancelled" = "cancelled",
	"rejected" = "rejected",
	"failed" = "failed",
	"waitingForApproval" = "waitingForApproval",
	"permissionInProgress" = "permissionInProgress",
	"waitingForIT" = "waitingForIT",
	"granted" = "granted",
	"revoked" = "revoked"
}

export type TTicketStatus = keyof typeof TicketStatus;
export const TICKET_STATUSES: TTicketStatus[] = Object.keys(TicketStatus) as TTicketStatus[];

const defaults = {
	approvalRequests: null as List<TicketApprovalRequestModel> | null,
	auditLogs: null as List<TicketAuditLogModel> | null,
	comments: null as List<TicketCommentModel> | null,
	createdAt: new Date(0),
	creatorId: "",
	duration: 0 as TTicketDuration,
	id: "",
	justification: "",
	number: 0,
	receiverId: "",
	status: "" as TTicketStatus,
	targets: null as List<TicketTargetModel> | null,
	ticketingIntegrationTicket: null as TicketingIntegrationTicketModel | null,
	ticketApprovalResponses: null as List<TicketApprovalResponseModel> | null,
	ticketPermissions: null as List<TicketPermissionModel> | null,
	updatedAt: new Date(0)
};

export class TicketModel extends Record<typeof defaults>(defaults) {
	static fromServerData(data: unknown): TicketModel {
		const {
			approvalRequests,
			auditLogs,
			comments,
			createdAt,
			creatorId,
			duration,
			id,
			number,
			receiverId,
			status,
			targets,
			ticketApprovalResponses,
			ticketPermissions,
			ticketingIntegrationTicket,
			updatedAt
		} = data as typeof defaults;

		const commentsList = comments ? List(comments.map(TicketCommentModel.fromServerData)) : null;

		return new TicketModel({
			approvalRequests: approvalRequests
				? List(approvalRequests.map(TicketApprovalRequestModel.fromServerData)).sortBy(({ sortOrder }) => sortOrder)
				: null,
			auditLogs: auditLogs ? List(auditLogs.map(TicketAuditLogModel.fromServerData)) : null,
			comments: commentsList,
			createdAt: new Date(createdAt),
			creatorId,
			duration,
			id,
			justification: commentsList && commentsList.size ? commentsList?.get(0)?.content : "",
			number,
			receiverId,
			status,
			targets: targets ? List(targets.map(TicketTargetModel.fromServerData)) : null,
			ticketApprovalResponses: ticketApprovalResponses
				? List(ticketApprovalResponses.map(TicketApprovalResponseModel.fromServerData))
				: null,
			ticketPermissions: ticketPermissions
				? List(
						ticketPermissions.map(TicketPermissionModel.fromServerData).sort((a, b) => {
							if (a.type === "regular") return -1;
							if (b.type === "regular") return 1;
							return 0;
						})
					)
				: null,
			ticketingIntegrationTicket: ticketingIntegrationTicket
				? TicketingIntegrationTicketModel.fromServerData(ticketingIntegrationTicket)
				: null,
			updatedAt: new Date(updatedAt)
		});
	}
}
