import { Record } from "immutable";

export type TPermissions = {
	integrationResourceRoleInfo: {
		name: string;
	};
	integrationResource: {
		name: string;
		integration: { name: string };
	};
};

const defaults = {
	id: "",
	date: new Date(),
	description: "",
	actionType: "",
	integrationId: "" || null,
	source: "",
	users: [] as string[],
	permissions: [] as TPermissions[],
	account: "",
	authorType: "",
	action: "",
	subject: "",
	target: "",
	status: "",
	json: {}
};

export class SessionAuditLogModel extends Record<typeof defaults>(defaults) {
	static readonly type = "sessionAuditLog";
	static fromServerData(data: unknown) {
		const {
			id,
			date,
			description,
			actionType,
			integrationId,
			source,
			users,
			permissions,
			account,
			authorType,
			action,
			subject,
			target,
			status,
			json
		} = data as typeof defaults;

		return new SessionAuditLogModel({
			id,
			date,
			description,
			actionType,
			integrationId,
			source,
			users,
			permissions,
			account,
			authorType,
			action,
			subject,
			target,
			status,
			json
		});
	}
}
