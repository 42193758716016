import { MultipleSelect } from "components/ui/MultipleSelect";
import { TICKET_STATUSES, TTicketStatus } from "models/TicketModel";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TextOption } from "components/ui/selectOptions/TextOption";
import type { IFilterProps } from "../../";

const TRANSLATION_PREFIX = "common.ticketFilters";

export const StatusFilter: FC<IFilterProps> = ({ onFiltersChange, ticketFilters }) => {
	const { t } = useTranslation();

	const changeStatus = useCallback(
		(status: TTicketStatus[] | null) => {
			onFiltersChange(current => ({
				...current,
				status: status || undefined
			}));
		},
		[onFiltersChange]
	);

	const getOptionLabel = useCallback(
		(option: TTicketStatus): string => {
			return t(`common.ticketStatus.${option}`);
		},
		[t]
	);

	const value = useMemo(() => {
		if (!ticketFilters.status) return [];
		return Array.isArray(ticketFilters.status) ? ticketFilters.status : [ticketFilters.status];
	}, [ticketFilters.status]);

	return (
		<MultipleSelect
			getOptionLabel={getOptionLabel}
			label={t(`${TRANSLATION_PREFIX}.status`)}
			onChange={changeStatus}
			renderOption={TextOption}
			options={TICKET_STATUSES}
			value={value}
		/>
	);
};
