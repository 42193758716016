import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sortBy from "lodash/sortBy";
import { Typography } from "components/ui/Typography";
import { Select, type TTargetValue, type ISelectProps } from "components/ui/Select";
import { useUsersSelect } from "hooks/useUsersSelect";
import { useStyles } from "./styles";
import type { UserModel } from "models/UserModel";
import type { TInherit } from "utils/ui/select";

export type TUserSelectOption = UserModel | TInherit;

const getUserName = (user: TUserSelectOption) => (typeof user !== "string" ? user.fullName || "" : user);
const getUserId = (user?: TUserSelectOption) => (typeof user !== "string" ? user?.id || "" : user);

const getLabel = (user: TUserSelectOption) => getUserName(user);
const equality = (option?: TUserSelectOption, value?: TUserSelectOption) => getUserId(option) === getUserId(value);
const renderLabel = (option: TUserSelectOption) => (
	<Typography variant="text_title_sb">{getUserName(option)}</Typography>
);
const sortOptions = (options: TUserSelectOption[]) => sortBy(options, getLabel);

const TRANSLATION_PREFIX = "common.userSelectInput";

export type TUserSelectProps = Omit<ISelectProps<TUserSelectOption>, "options" | "getOptionLabel"> & {
	disabledIds?: string[];
	getOptionLabel?: (option: TUserSelectOption) => string;
	staticOptions?: TUserSelectOption[];
	selectedIds?: string[];
};
export const UserSelect: FC<TUserSelectProps> = ({
	className,
	innerRef,
	onChange,
	placeholder: propsPlaceholder,
	renderLabel: propRenderLabel,
	disabledIds = [],
	selectedIds = [],
	staticOptions,
	...selectProps
}) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const [isFocused, setIsFocused] = useState(false);
	const {
		items: users,
		selectedItems,
		isLoading
	} = useUsersSelect(query, {
		selectedIds,
		includeDeleted: false,
		initialFetch: isFocused
	});
	const options = useMemo(() => {
		const result = users.filter(user => user.fullName.length && !disabledIds.includes(user.id)).toArray();
		if (staticOptions?.length) {
			return [...staticOptions, ...result];
		}
		return result;
	}, [disabledIds, staticOptions, users]);

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const handleValueChange = useCallback(
		(user: TUserSelectOption | null) => {
			if (!user) return;
			onChange?.(typeof user === "object" ? user : null);
			setQuery("");
		},
		[onChange]
	);

	const placeholder = propsPlaceholder ?? t(`${TRANSLATION_PREFIX}.placeholder`);

	const onFocus = useCallback(() => {
		if (isFocused) return;
		setIsFocused(true);
	}, [isFocused]);

	// props.value is being used as a fallback when selectedIds passed
	const selectedValue = selectedIds?.length && selectedItems.size ? selectedItems.first() : (selectProps.value ?? null);

	return (
		<div className={classNames(classes.container, className)} ref={innerRef}>
			<Select
				getOptionLabel={getLabel}
				isOptionEqualToValue={equality}
				options={options}
				placeholder={placeholder}
				renderLabel={propRenderLabel ?? renderLabel}
				sort={sortOptions}
				onChange={handleValueChange}
				loading={isLoading}
				onInputChange={onInputChange}
				inputValue={query}
				filter={null}
				onFocus={onFocus}
				value={selectedValue}
				{...selectProps}
			/>
		</div>
	);
};
