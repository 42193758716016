import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ResourcesIcon } from "components/ui/Icons/ResourcesIcon";
import { IntegrationModel } from "models/IntegrationModel";
import { Typography } from "components/ui/legacy/Typography";
import { Table } from "components/ui/Table";
import { getIntegrationResources } from "api/integrationResources";
import { ASC, usePagination } from "hooks/usePagination";
import { CommonAccordion } from "components/common/CommonAccordion";
import { Input } from "components/ui/Input";
import { PageSelect } from "components/ui/PageSelect";
import { Button } from "components/ui/Button";
import { AddIcon } from "components/ui/Icons/AddIcon";
import { Separator } from "components/ui/Separator";
import { SortableTableHeader } from "components/common/SortableTableHeader";
import { SortTableProvider, useSortTableContext } from "context/sortingTableContext";
import { getIntegrationResourceFilters } from "utils/api/filters";
import { useStopPropagation } from "hooks/useStopPropagation";
import { EditIcon } from "components/ui/Icons/EditIcon";
import { IntegrationResourceIntegrationIdFilter } from "filters/integrationResource";
import { useStyles } from "./styles";
import { ResourceRow } from "./ResourceRow";
import type { IPaginatedSearchOptions } from "utils/searchUtils";

const PAGE_SIZE = 10;
const TRANSLATION_PREFIX = "pages.integration.resource" as const;

const fields = ["name", "owner", "approvalAlgorithm", "type"];

interface IProps {
	integration: IntegrationModel;
	onAddResource?: () => void;
	withUnmanaged?: boolean;
}

const ResourceBlockContent: FC<IProps> = ({ integration, onAddResource, withUnmanaged = false }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const [query, setQuery] = useState("");
	const navigate = useNavigate();

	const {
		state: { sortFields, sortOrder }
	} = useSortTableContext();

	const fetchResources = useCallback(
		(paginationOptions: IPaginatedSearchOptions) => {
			const queryOptions = {
				...paginationOptions.pagination,
				...paginationOptions.sort,
				withUnmanaged,
				filters: getIntegrationResourceFilters({ integrationId: [integration.id], name: [query] })
			};

			return getIntegrationResources(queryOptions);
		},
		[integration, query, withUnmanaged]
	);

	const { totalPages, currentPageNumber, page, setCurrentPageNumber, isLoading, lastPage, setPartialItem } =
		usePagination({
			fetchItems: fetchResources,
			perPage: PAGE_SIZE,
			sortOrder,
			sortFields,
			initialFilter: null,
			disableSearch: false
		});

	const onInputChange = useCallback((event: { target: { value: string } }) => {
		setQuery(event.target.value);
	}, []);

	const headers: string[] = [
		t(`${TRANSLATION_PREFIX}.name`),
		t(`${TRANSLATION_PREFIX}.owner`),
		t(`${TRANSLATION_PREFIX}.workflow`),
		t(`${TRANSLATION_PREFIX}.type`)
	];

	const handleAddResource = useStopPropagation(onAddResource);

	const handleBulkActionsClick = useCallback(() => {
		const integrationFilter = new IntegrationResourceIntegrationIdFilter({ value: [integration.id] });
		navigate({
			pathname: "/bulkActions",
			search: `?tab=resource&${integrationFilter.toURLSearchParams().toString()}`
		});
	}, [integration.id, navigate]);

	return (
		<CommonAccordion
			title={
				<div className={classes.titleContainer}>
					<div className={classes.titlePart}>
						<ResourcesIcon />
						<Typography variant="h3">{t("pages.integration.resources")}</Typography>
						<Typography variant="small">{`( ${t("number", { value: integration.resourcesCount || 0 })} )`}</Typography>
						{integration.lastResourcesSync && (
							<>
								<Separator className={classes.separator} />
								<Typography relative variant="small" className={classes.lighterText}>
									{t("common.resourceHeader.lastSync", { date: integration.lastResourcesSync })}
								</Typography>
							</>
						)}
					</div>
					<div className={classes.titlePart}>
						<Button
							prefix={<EditIcon size="small" />}
							size="medium"
							variant="secondary"
							onClick={handleBulkActionsClick}>
							{t("pages.integration.bulkActions")}
						</Button>

						{onAddResource && (
							<Button variant="secondary" size="medium" prefix={<AddIcon />} onClick={handleAddResource}>
								{t("buttons.add")}
							</Button>
						)}
					</div>
				</div>
			}>
			<Input
				className={classes.searchBar}
				onChange={onInputChange}
				placeholder={t("pages.integration.resource.search.placeholder")}
				value={query}
				variant="search"
			/>

			<Table gridColumns="repeat(4, 1fr)" loading={isLoading}>
				<Table.Row>
					{headers.map((title, index) => {
						const field = fields.at(index)!;
						return <SortableTableHeader field={field} key={field} title={title} />;
					})}
				</Table.Row>
				{(isLoading ? lastPage : page)?.map(resource => (
					<ResourceRow resource={resource} key={resource.id} onResourceChanged={setPartialItem} />
				)) || null}
			</Table>
			{totalPages > 1 && (
				<PageSelect
					changePage={setCurrentPageNumber}
					currentPageNumber={currentPageNumber}
					pagesCount={totalPages}
					pagesShownAmount={3}
				/>
			)}
		</CommonAccordion>
	);
};

export const ResourcesBlock: FC<IProps> = props => (
	<SortTableProvider defaultSortField="name" defaultSortOrder={ASC}>
		<ResourceBlockContent {...props} />
	</SortTableProvider>
);
