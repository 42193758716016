import React, { useCallback, useEffect, useMemo } from "react";
import { useAgentTokens } from "hooks/useAgentTokens";
import { Select } from "components/ui/Select";
import { AgentTokenModel } from "models/AgentTokenModel";
import { useTranslation } from "react-i18next";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { useStyles } from "./styles";

export interface ITokenOption {
	id: string;
	label: string;
}

interface IProps {
	onChange: (option: ITokenOption | null) => void;
	value: ITokenOption | null;
	disabled?: boolean;
}

const getTokenLabel = (token: ITokenOption) => token.label;
const convertTokenToOption = (token: AgentTokenModel) => ({
	id: token.id,
	label: token.name
});

export const DEFAULT_AGENT_TOKEN_OPTION_ID = "entitle";

export const AgentTokenSelect: FC<IProps> = ({ value, onChange, disabled = false }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const agentTokens = useAgentTokens();
	const shouldDisplay = useMemo(() => Boolean(agentTokens?.size), [agentTokens]);
	const isOptionEqualToValue = useCallback((option: ITokenOption) => option.id === value?.id, [value]);
	const defaultToken: ITokenOption = useMemo(
		() => ({
			id: DEFAULT_AGENT_TOKEN_OPTION_ID,
			label: t("pages.integration.agentToken.defaultOption")
		}),
		[t]
	);

	const tokenOptions = useMemo(() => {
		return agentTokens ? [defaultToken, ...agentTokens.map(convertTokenToOption)] : [defaultToken];
	}, [defaultToken, agentTokens]);

	useEffect(() => {
		onChange(defaultToken);
	}, [defaultToken, onChange]);

	return (
		<div className={classes.tokenSelectContainer}>
			{shouldDisplay && (
				<Select
					className={classes.tokenSelectInput}
					disabled={tokenOptions?.length === 1 || disabled}
					getOptionLabel={getTokenLabel}
					isOptionEqualToValue={isOptionEqualToValue}
					label={t("pages.integration.agentToken.title")}
					onChange={onChange}
					renderOption={TextOption}
					options={tokenOptions}
					placeholder={t("pages.integration.agentToken.placeholder")}
					value={value || defaultToken}
				/>
			)}
		</div>
	);
};
