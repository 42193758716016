import React, { useMemo } from "react";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { getDynamicSizeIcon } from "components/ui/dynamicSizeIcon";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { SelectOption } from "../SelectOption/SelectOption";
import type { TOptionComponent } from "utils/ui/select";

const IntegrationOption: TOptionComponent<IntegrationModel> = ({
	disabled = false,
	isSelected = false,
	onSelect,
	option,
	optionKey
}) => {
	const Icon = useMemo(() => {
		return option.imageUrl ? getDynamicSizeIcon(<img src={option.imageUrl} />) : IntegrationIcon;
	}, [option.imageUrl]);

	return (
		<SelectOption
			PrefixIcon={Icon}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow textVariant="body_reg" content={option.name} />
		</SelectOption>
	);
};

IntegrationOption.isSelectOption = true;

export { IntegrationOption };
