import React, { useMemo } from "react";
import classNames from "classnames";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

type TProps = {
	element: JSX.Element;
	size?: "small" | "medium" | "large" | "xl" | number;
};

export const DynamicSizeIcon: FC<TProps> = ({ className, element, size }) => {
	const classes = useStyles({ size: typeof size === "number" ? size : undefined });
	const sizeClassName = useMemo(() => {
		if (typeof size === "number") {
			return classes.customSize;
		}
		return size ? requirePropertyOf(classes, size) : undefined;
	}, [size, classes]);

	return <div className={classNames(sizeClassName, classes.icon, className)}>{element}</div>;
};

export const getDynamicSizeIcon = (element: JSX.Element): IconComponent => {
	const Icon: IconComponent = ({ size }) => <DynamicSizeIcon size={size} element={element} />;
	return Icon;
};
