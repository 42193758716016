import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FilterExpressionEmptyState } from "components/common/FilterExpressionEmptyState";
import { UsersIcon } from "components/ui/Icons/UsersIcon";
import { useUsersSelect } from "hooks/useUsersSelect";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { IntegrationLogsMultiSelectFilterExpression } from "../IntegrationLogsMultiSelectFilterExpression";

type TIntegrationFilterExpressionProps = {
	onRemove: () => void;
};

export const UserFilterExpression: FC<TIntegrationFilterExpressionProps> = ({ onRemove }) => {
	const { t } = useTranslation();
	const [searchQuery, setSearchQuery] = useState("");
	const { items: users } = useUsersSelect(searchQuery);

	const emptyState = (
		<FilterExpressionEmptyState text={t("pages.auditLog.integrationLogs.filter.userEmptyState")} Icon={UsersIcon} />
	);

	return (
		<IntegrationLogsMultiSelectFilterExpression
			fetchValues={users}
			filterEmptyState={emptyState}
			filterName="userId"
			inputPlaceholder={t("pages.auditLog.integrationLogs.filter.userPlaceholder")}
			onFilterRemove={onRemove}
			onInputChange={setSearchQuery}
			optionRenderer={UserNodeOption}
			renderType="UserCard"
			title={t("pages.auditLog.integrationLogs.filter.user")}
		/>
	);
};
