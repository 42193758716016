import React, { useMemo } from "react";
import { RoleBar } from "components/common/RoleBar";
import { RoleBarIntegration } from "components/common/RoleBar/components/RoleBarIntegration";
import { RoleBarResource } from "components/common/RoleBar/components/RoleBarResource";
import { RoleBarRole } from "components/common/RoleBar/components/RoleBarRole";
import { IntegrationModel } from "models/IntegrationModel";
import { IntegrationResourceModel } from "models/IntegrationResourceModel";
import { IntegrationResourceRoleModel } from "models/IntegrationResourceRoleModel";

const COLUMNS_WIDTHS = {
	integration: "172px" as `${number}px`,
	resource: "172px" as `${number}px`,
	role: "172px" as `${number}px`
};
type TProps = {
	integration?: IntegrationModel;
	resource?: IntegrationResourceModel;
	role: IntegrationResourceRoleModel;
};
export const PermissionsRoleBar: FC<TProps> = ({ integration, resource, role }) => {
	const columns = useMemo(
		() => [
			{
				content: <RoleBarIntegration name={integration?.name || ""} imageUrl={integration?.imageUrl || ""} />,
				width: COLUMNS_WIDTHS.integration
			},
			{
				content: (
					<RoleBarResource
						name={resource?.name || ""}
						euid={resource?.euid || ""}
						description={resource?.description || undefined}
						tags={resource?.calculatedTags}
						type={resource?.type}
					/>
				),
				width: COLUMNS_WIDTHS.resource
			},
			{
				content: <RoleBarRole name={role.name} />,
				width: COLUMNS_WIDTHS.role
			}
		],
		[
			integration?.imageUrl,
			integration?.name,
			resource?.calculatedTags,
			resource?.description,
			resource?.euid,
			resource?.name,
			resource?.type,
			role.name
		]
	);
	return <RoleBar noInteraction columns={columns} />;
};
