import React from "react";
import { WorkflowsIcon } from "components/ui/Icons/WorkflowsIcon";
import { ApprovalAlgorithmModel } from "models/ApprovalAlgorithmModel";
import { TooltipOnOverflow } from "components/ui/TooltipOnOverflow";
import { INHERIT, type TInherit, type TOptionComponentProps } from "utils/ui/select";
import { SelectOption } from "../SelectOption/SelectOption";

function WorkflowOption<T extends ApprovalAlgorithmModel | TInherit>({
	disabled = false,
	getTextContent,
	isSelected = false,
	onSelect,
	option,
	optionKey
}: TOptionComponentProps<T>) {
	const content = option === INHERIT ? (getTextContent ? getTextContent(option) : option) : option.name;
	return (
		<SelectOption
			PrefixIcon={option !== INHERIT ? WorkflowsIcon : undefined}
			disabled={disabled}
			isSelected={isSelected}
			key={optionKey}
			onSelect={onSelect}
			value={option}>
			<TooltipOnOverflow content={content} textVariant="body_reg" />
		</SelectOption>
	);
}

WorkflowOption.isSelectOption = true;

export { WorkflowOption };
