import { List } from "immutable";
import { RuleModel } from "models/RuleModel";
import React, { useMemo } from "react";
import classNames from "classnames";
import { Typography } from "components/ui/Typography";
import { StaticChip } from "components/ui/chips/StaticChip";
import { Section } from "components/ui/Section";
import { Tooltip } from "components/ui/Tooltip";
import { IconButton } from "components/ui/IconButton";
import { InfoIcon } from "components/ui/Icons/InfoIcon";
import { useTranslation } from "react-i18next";
import { RulesList } from "../RulesList";
import { useStyles } from "./styles";

type TRuleTypeSectionProps = {
	title: string;
	Icon?: IconComponent;
	rules: List<RuleModel>;
	changeRulePriority: (rule: RuleModel, newPriority: number) => void;
	infoExplanation?: string;
};

export const RuleTypeSection: FC<TRuleTypeSectionProps> = React.memo(function RuleTypeSection({
	className,
	title,
	Icon,
	rules,
	infoExplanation,
	changeRulePriority
}) {
	const classes = useStyles();
	const { t } = useTranslation();

	const titleComponent = useMemo(() => {
		return (
			<>
				{Icon && <Icon size={24} />}
				<Typography variant="body_sb">{title}</Typography>
				<StaticChip size="small" variant="regular">
					{t("number", { value: rules.size })}
				</StaticChip>
				{infoExplanation && (
					<Tooltip className={classes.explanationTooltip} content={infoExplanation}>
						<IconButton size="medium">
							<InfoIcon />
						</IconButton>
					</Tooltip>
				)}
			</>
		);
	}, [classes.explanationTooltip, Icon, infoExplanation, rules.size, title, t]);

	return (
		<Section
			expanded
			title={titleComponent}
			className={className}
			contentClassName={classNames(classes.sectionContent)}>
			<RulesList changeRulePriority={changeRulePriority} rules={rules} />
		</Section>
	);
});
