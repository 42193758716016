import { EventEmitter } from "events";
import React, { useCallback, useMemo } from "react";
import { Stepper } from "components/common/Stepper";
import { TransitionablePage } from "components/templates/TransitionablePage";
import { useIsOpenState } from "hooks/useIsOpenState";
import { urlFieldsParser } from "utils/urlFormDataParser";
import { SelectRolesStep } from "./components/SelectRolesStep";
import { SelectDurationStep } from "./components/SelectDurationStep";
import { RequestReasonStep } from "./components/RequestReasonStep";
import { SummaryStep } from "./components/SummaryStep";
import { NewRequestFormProvider } from "./newRequestFormContext";
import { NewRequestDataProvider } from "./newRequestDataContext";
import { ExitNewRequestModal } from "./components/ExitNewRequestModal";

export const NewRequestPage: FC = ({ className, innerRef }) => {
	const { open, close, isOpen } = useIsOpenState();
	const emitter = useMemo(() => new EventEmitter(), []);

	const onExitClick = useCallback(() => {
		open();
	}, [open]);

	const onExit = useCallback(() => {
		emitter?.emit("resetStepper");
	}, [emitter]);

	const steps = useMemo(() => {
		return [
			{
				index: 0,
				step: <SelectRolesStep onExit={onExitClick} />
			},
			{
				index: 1,
				step: <SelectDurationStep onExit={onExitClick} />
			},
			{
				index: 2,
				step: <RequestReasonStep onExit={onExitClick} />
			},
			{
				index: 3,
				step: <SummaryStep onExit={onExitClick} />
			}
		];
	}, [onExitClick]);

	const activeStepIndex = useMemo(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const urlFormData = urlFieldsParser(searchParams);
		const originalTicketId = searchParams.get("originalTicket");
		return urlFormData || originalTicketId ? 3 : undefined;
	}, []);

	return (
		<NewRequestDataProvider>
			<NewRequestFormProvider>
				<TransitionablePage>
					<ExitNewRequestModal close={close} isOpen={isOpen} onExit={onExit} />
					<Stepper
						steps={steps}
						activeStepIndex={activeStepIndex}
						innerRef={innerRef}
						className={className}
						resetEventEmitter={emitter}
					/>
				</TransitionablePage>
			</NewRequestFormProvider>
		</NewRequestDataProvider>
	);
};
