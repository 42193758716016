import React, { useCallback, useMemo, useState } from "react";
import union from "lodash/union";
import { getUniqueKey } from "utils/ui/getUniqueKey";
import { Select } from "components/ui/Select";
import { TextOption } from "components/ui/selectOptions/TextOption";
import { getLabel } from "utils/ui/select";
import { FilterExpressionRelation } from "../FilterExpressionRelation";
import { FilterHeader } from "../FilterHeader";
import { useGetOperatorLabel } from "../../useRenderOperatorLabel";
import { SelectRowEmptyState } from "../SelectRowEmptyState";
import { useStyles } from "./styles";
import type { TMultiSelectAndTextProps } from "../../types";
import type { TFilterOperator } from "types/filters";

export function MultiSelectAndTextFilter({
	className,
	emptyState = null,
	filter,
	getMoreOptions,
	getOperatorLabel: propGetOperatorLabel,
	getOptionLabel,
	innerRef,
	inputPlaceholder,
	isLoading,
	onOperatorSelect,
	onOptionSelect,
	onRemoveFilter,
	onReset,
	operators,
	options,
	relation = "or",
	renderSelected,
	selected,
	selectedOperator,
	optionRenderer,
	title
}: TProps<TMultiSelectAndTextProps>) {
	const classes = useStyles();
	const [value, setValue] = useState("");

	const getOperatorLabel = useGetOperatorLabel();

	const selectedRow = useMemo(() => {
		if (!selected.length) {
			return <SelectRowEmptyState content={emptyState} />;
		}
		const selectedComponents = selected.map((item, index) => {
			return (
				<React.Fragment key={getUniqueKey(item, index.toString())}>
					{renderSelected(item)}
					{index !== selected.length - 1 ? <FilterExpressionRelation relation={relation} /> : null}
				</React.Fragment>
			);
		});
		return (
			<>
				{selectedComponents}
				<FilterExpressionRelation disabled relation={relation} />
			</>
		);
	}, [selected, relation, emptyState, renderSelected]);

	const handleOperatorChange = useCallback(
		(operator: TFilterOperator | null) => {
			if (operator) {
				onOperatorSelect(operator);
			}
		},
		[onOperatorSelect]
	);

	const handleValueChange = useCallback(
		(option: string | null) => {
			if (option) {
				onOptionSelect(option);
			}
		},
		[onOptionSelect]
	);

	const onInputChange = useCallback(
		(event: { target: { value: string } }) => {
			getMoreOptions?.(event.target.value);
			setValue(event.target.value);
		},
		[getMoreOptions]
	);

	const optionsWithValue = useMemo(() => {
		const optionsWithFreeTexts = union(selected, options);
		if (value && !optionsWithFreeTexts.some(option => option === value)) {
			return [value].concat(optionsWithFreeTexts);
		}
		return optionsWithFreeTexts;
	}, [options, value, selected]);

	const isOptionEqualToValue = useCallback((option: string) => selected.some(value => value === option), [selected]);

	const sortOptions = useCallback((options: string[]) => options, []); // we did the sorting beforehand , it we put nothing it will sort alphabetically and I want the selected and free text to appear first

	const inputs = useMemo(
		() => (
			<>
				<Select
					className={classes.operatorSelect}
					getOptionLabel={propGetOperatorLabel || getOperatorLabel}
					hideClear
					onChange={handleOperatorChange}
					renderOption={TextOption}
					options={operators}
					value={selectedOperator}
				/>
				<Select
					filter={filter}
					hideClear
					getOptionLabel={getOptionLabel || getLabel}
					isOptionEqualToValue={isOptionEqualToValue}
					loading={isLoading}
					onChange={handleValueChange}
					onInputChange={onInputChange}
					renderOption={optionRenderer}
					options={optionsWithValue}
					placeholder={inputPlaceholder}
					resetValueOnSelect
					sort={sortOptions}
					value={null}
				/>
			</>
		),
		[
			classes.operatorSelect,
			propGetOperatorLabel,
			getOperatorLabel,
			handleOperatorChange,
			operators,
			selectedOperator,
			filter,
			getOptionLabel,
			isOptionEqualToValue,
			isLoading,
			handleValueChange,
			onInputChange,
			optionRenderer,
			optionsWithValue,
			inputPlaceholder,
			sortOptions
		]
	);
	return (
		<>
			<FilterHeader
				className={className}
				title={title}
				onRemoveFilter={onRemoveFilter}
				onReset={onReset}
				innerRef={innerRef}
				hasSelection={selected.length > 0}
				inputs={inputs}
			/>
			<div className={classes.content}>{selectedRow}</div>
		</>
	);
}
