import React, { useEffect, useMemo, useState } from "react";
import { List } from "immutable";
import { Chip, TChipSize } from "components/ui/chips/Chip";
import { IntegrationIcon } from "components/ui/Icons/IntegrationIcon";
import { GroupIcon } from "components/ui/Icons/GroupIcon";
import { useIsOpenState } from "hooks/useIsOpenState";
import { WebhookIcon } from "components/ui/Icons/WebhookIcon";
import { UserCircleIcon } from "components/ui/Icons/UserCircleIcon";
import { DoneCircleIcon } from "components/ui/Icons/DoneCircleIcon";
import { CloseCircleIcon } from "components/ui/Icons/CloseCircleIcon";
import { PendingIcon } from "components/ui/Icons/PendingIcon";
import { useUser } from "hooks/useUser";
import { ApproversModal } from "../ApproversModal";
import { EmailTooltip } from "../EmailTooltip";
import { TextWithDirectoryIcon } from "../TextWithDirectoryIcon";
import { UserTooltip } from "../UserTooltip";
import type { UserModel } from "models/UserModel";

type TApproverType = "user" | "group" | "system" | "auto" | "webhook";
export type TApprovalStatus = "approved" | "rejected" | "pending";

interface IProps {
	approvalStatus: TApprovalStatus;
	approverIds?: string[][];
	approverName: string;
	approverType: TApproverType;
	entityIcon?: JSX.Element;
	entityId?: string;
	isDeleted?: boolean;
	noBorder?: boolean;
	noModal?: boolean;
	showStatus?: boolean;
	size?: TChipSize;
	tooltip?: string;
	withIcon?: boolean;
}

export type { IProps as IApprovalStatusChipProps };

const getApprovalIcon = (approvalStatus: TApprovalStatus) => {
	switch (approvalStatus) {
		case "approved":
			return DoneCircleIcon;
		case "rejected":
			return CloseCircleIcon;
		case "pending":
			return PendingIcon;
		default:
			return undefined;
	}
};

const getApproverIcon = (approverType: TApproverType) => {
	switch (approverType) {
		case "user":
			return UserCircleIcon;
		case "group":
			return GroupIcon;
		case "system":
			return IntegrationIcon;
		case "webhook":
			return WebhookIcon;
		default:
			return undefined;
	}
};

export const ApprovalStatusChip: FC<IProps> = ({
	approvalStatus,
	approverIds,
	approverName,
	approverType,
	children,
	className,
	entityId,
	isDeleted = false,
	noBorder = true,
	noModal,
	size = "medium",
	tooltip,
	withIcon = true
}) => {
	const approverIcon = getApproverIcon(approverType);
	const approvalIcon = getApprovalIcon(approvalStatus);
	const user = useUser(entityId);

	const [approverIdsList, setApproverIdsList] = useState<List<List<string>>>(List(approverIds?.map(List) ?? []));

	useEffect(() => {
		setApproverIdsList(current => {
			const newApproverIdsList = List(approverIds?.map(List) ?? []);
			return current.equals(newApproverIdsList) ? current : newApproverIdsList;
		});
	}, [approverIds]);

	const asMatrix = useMemo(
		() => approverIdsList.map(approverGroup => approverGroup.toArray()).toArray(),
		[approverIdsList]
	);

	const content = useMemo(() => {
		if (children) return children;
		if (!asMatrix.length) {
			if (user) {
				<UserApprover user={user} approverName={approverName} isDeleted={isDeleted} />;
			}
			return <GroupApprover approverName={approverName} isDeleted={isDeleted} tooltip={tooltip} />;
		}
		return "system";
	}, [children, asMatrix.length, tooltip, isDeleted, approverName, user]);

	const prefixIcon = withIcon ? approverIcon || approvalIcon : undefined;

	if (content === "system")
		return (
			<SystemApprover
				prefixIcon={prefixIcon}
				noBorder={noBorder}
				size={size}
				approverName={approverName}
				isDeleted={isDeleted}
				approvers={asMatrix}
				tooltip={tooltip}
				noModal={noModal}
			/>
		);

	return (
		<Chip variant="regular" PrefixIcon={prefixIcon} className={className} size={size}>
			{approverName}
		</Chip>
	);
};

type TUserApproverProps = Pick<IProps, "approverName" | "isDeleted"> & { user: UserModel };

const UserApprover: FC<TUserApproverProps> = ({ user, approverName, isDeleted }) => {
	return (
		<UserTooltip user={user}>
			<TextWithDirectoryIcon value={approverName} isDeleted={isDeleted} />
		</UserTooltip>
	);
};

type TGroupApproverProps = Pick<IProps, "approverName" | "isDeleted" | "tooltip">;

const GroupApprover: FC<TGroupApproverProps> = ({ approverName, isDeleted, tooltip }) => {
	return (
		<EmailTooltip email={tooltip} isDeleted={isDeleted}>
			<TextWithDirectoryIcon value={approverName} isDeleted={isDeleted} />
		</EmailTooltip>
	);
};

type TSystemApproverProps = Pick<IProps, "approverName" | "isDeleted" | "tooltip" | "noModal"> & {
	approvers: string[][];
	prefixIcon?: IconComponent;
	noBorder?: boolean;
	size?: TChipSize;
};

const SystemApprover: FC<TSystemApproverProps> = ({
	approverName,
	approvers,
	className,
	isDeleted,
	noModal = false,
	prefixIcon,
	size,
	tooltip
}) => {
	const approverModalIsOpenState = useIsOpenState();

	return (
		<Chip
			variant="regular"
			PrefixIcon={prefixIcon}
			className={className}
			size={size}
			onClick={approverModalIsOpenState.open}>
			<EmailTooltip email={tooltip} isDeleted={isDeleted}>
				<ApproversModal
					isOpen={!noModal && approverModalIsOpenState.isOpen}
					close={approverModalIsOpenState.close}
					approvers={approvers}
				/>
				<TextWithDirectoryIcon value={approverName} isDeleted={isDeleted} />
			</EmailTooltip>
		</Chip>
	);
};
