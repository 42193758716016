import React, { useCallback, useMemo, useState } from "react";
import isNil from "lodash/isNil";
import { UserModel } from "models/UserModel";
import { UserCard } from "components/common/UserCard";
import { useTranslation } from "react-i18next";
import { Chip } from "components/ui/chips/Chip";
import { OwnerIcon } from "components/ui/Icons/OwnerIcon";
import { useUsersSelect } from "hooks/useUsersSelect";
import { INHERIT, type TInherit } from "utils/ui/select";
import { UserNodeOption } from "components/ui/selectOptions/UserNodeOption";
import { TUpdateExpressionProps } from "../../updates.types";
import { SingleSelectAttributeExpression } from "../SingleSelectAttributeExpression";
import { useStyles } from "./styles";
import type { TTargetValue } from "components/ui/Select";

const ATTRIBUTE = "ownerUserId" as const;

type TOption = UserModel | TInherit;

export const ResourceOwnerAttributeExpression: FC<Omit<TUpdateExpressionProps<"ownerUserId">, "ruleType">> = ({
	innerRef,
	selected,
	onRemoveAttribute,
	setUpdatesAttribute
}) => {
	const { t } = useTranslation("translation", { keyPrefix: "pages.rules.updates" });
	const { t: sharedTranslation } = useTranslation("translation", { keyPrefix: "shared" });
	const classes = useStyles();
	const [query, setQuery] = useState("");
	const {
		items: users,
		isLoading,
		selectedItems
	} = useUsersSelect(query, { includeDeleted: false, selectedIds: selected ? [selected] : undefined });
	const onReset = useCallback(() => {
		setUpdatesAttribute(ATTRIBUTE, undefined);
	}, [setUpdatesAttribute]);

	const onOptionSelect = useCallback(
		(value: TOption) => setUpdatesAttribute(ATTRIBUTE, value === INHERIT ? null : value.id),
		[setUpdatesAttribute]
	);

	const renderSelected = useCallback(
		(value: TOption) =>
			value === INHERIT ? (
				<div className={classes.userNodeHeight}>
					<Chip PrefixIcon={OwnerIcon} size="large" variant="regular" selected onDelete={onReset}>
						{sharedTranslation("integrationDefault")}
					</Chip>
				</div>
			) : (
				<UserCard user={value} onDelete={onReset} selected />
			),
		[onReset, sharedTranslation, classes.userNodeHeight]
	);

	const options = useMemo(() => [INHERIT as TOption].concat(users.toArray()), [users]);

	const filterOptions = useCallback(
		(options: TOption[], inputValue: string) =>
			inputValue
				? options.filter(
						option => option !== INHERIT && option.fullName.toLowerCase().includes(inputValue.toLowerCase())
					)
				: options,
		[]
	);

	const onInputChange = useCallback((event: TTargetValue | React.ChangeEvent<HTMLInputElement>) => {
		setQuery(event.target.value);
	}, []);

	const selectedValue = useMemo(
		() => (isNil(selected) ? (selected === null ? INHERIT : undefined) : (selectedItems.first() ?? undefined)),
		[selected, selectedItems]
	);

	const getOptionLabel = useCallback(
		(option: TOption) => {
			return option === INHERIT ? sharedTranslation("integrationDefault") : option.fullName;
		},
		[sharedTranslation]
	);

	return (
		<SingleSelectAttributeExpression
			emptyState={<div className={classes.userNodeHeight} />}
			filter={filterOptions}
			getOptionLabel={getOptionLabel}
			innerRef={innerRef}
			inputPlaceholder={t(`placeholders.${ATTRIBUTE}`)}
			isLoading={isLoading}
			onInputChange={onInputChange}
			onOptionSelect={onOptionSelect}
			onRemoveAttribute={onRemoveAttribute}
			onReset={onReset}
			options={options}
			optionRenderer={UserNodeOption}
			renderSelected={renderSelected}
			selected={selectedValue}
			sort={null}
			title={t(`resources.${ATTRIBUTE}`)}
		/>
	);
};
