import React, { useCallback, useMemo } from "react";
import { TicketModel } from "models/TicketModel";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useRedirectModalContext } from "context/redirectModalContext";
import { useIsBeta } from "context/betaContext";
import { routes } from "routes/routes";
import { ExtraOptionsButton, TExtraOptions, TRequestAction, TSelectClickableOption } from "../ExtraOptionsButton";
import { RedirectTicketModal } from "../RedirectTicketModal";
import { useStyles } from "./styles";
import type { Placement } from "@popperjs/core";

interface IProps {
	ticket: TicketModel;
	disabled?: boolean;
	adminApprove?: (ticket: TicketModel) => void;
	adminDeny?: (ticket: TicketModel) => void;
	allowAdminActions?: boolean;
	position?: Placement;
	optionClassName?: string;
	size?: "small" | "medium" | "large";
	onClose?: () => void;
}

const TRANSLATION_PREFIX = "common.extraOptions";
const TICKET_ACTIONS_TRANSLATIONS_PREFIX = "common.ticketActions";

// in order the redirect will work you need to have a redirectModal rendered in that page
export const TicketExtraOptionsButton: FC<IProps> = ({
	ticket,
	className,
	size = "small",
	disabled = false,
	allowAdminActions = false,
	optionClassName,
	adminApprove,
	adminDeny,
	position,
	onClose
}) => {
	const { t } = useTranslation();
	const classes = useStyles();
	const navigate = useNavigate();
	const isBeta = useIsBeta();
	const {
		actions: { open, setTicket }
	} = useRedirectModalContext();

	const openRedirectModal = useCallback(() => {
		setTicket(ticket);
		open();
	}, [ticket, open, setTicket]);

	const reopenTicket = useCallback(() => {
		if (onClose) {
			onClose();
		}
		navigate({
			pathname: isBeta ? routes.newRequest.main : "/request",
			search: `originalTicket=${ticket.id}`
		});
	}, [isBeta, navigate, onClose, ticket.id]);

	const extraOptions = useMemo<TExtraOptions<TicketModel>>(() => {
		const options = new Map<TRequestAction, TSelectClickableOption<TicketModel>>();
		options.set("redirect", {
			value: "redirect",
			label: t(`${TRANSLATION_PREFIX}.redirect`),
			onClick: openRedirectModal
		});

		options.set("reopen", {
			value: "reopen",
			label: t(`${TRANSLATION_PREFIX}.reopen`),
			onClick: reopenTicket
		});

		if (allowAdminActions && adminApprove && adminDeny) {
			options.set("adminApprove", {
				value: "adminApprove",
				label: t(`${TICKET_ACTIONS_TRANSLATIONS_PREFIX}.adminApprove`),
				onClick: adminApprove
			});

			options.set("adminDeny", {
				value: "adminDeny",
				label: t(`${TICKET_ACTIONS_TRANSLATIONS_PREFIX}.adminDeny`),
				onClick: adminDeny
			});
		}

		return options;
	}, [t, openRedirectModal, reopenTicket, allowAdminActions, adminApprove, adminDeny]);

	return (
		<>
			<RedirectTicketModal />
			<ExtraOptionsButton
				item={ticket}
				position={position}
				extraOptions={extraOptions}
				disabled={disabled}
				size={size}
				optionClassName={optionClassName}
				className={classNames(className, classes.extraOptionsButton)}
			/>
		</>
	);
};
